import { LatLng } from "./simpleTypes";
import { GroupedConnectionPoint } from "../viewModel/groupedConnectionPoint";
import { Asset } from "./asset";
import { CableProperties, ICableProperties } from "./cableProperties";

export class Cable extends Asset implements ICableProperties {
  constructor(id: string, cableProperties: CableProperties) {
    super(id);
    this.geometry = cableProperties.geometry;
    this.startAssetId = cableProperties.startAssetId;
    this.endAssetId = cableProperties.endAssetId;
    this.consumersDownstream = cableProperties.consumersDownstream;
    this.consumersUpstream = cableProperties.consumersUpstream;
    this.actualPhasesAvailable = cableProperties.actualPhasesAvailable;
    this.length = cableProperties.length;
    this.cableType = cableProperties.cableType;
    this.cableTypeIsDefault = cableProperties.cableTypeIsDefault;
    this.cableTypeOriginal = cableProperties.cableTypeOriginal;
    this.cableTypeAssigned = cableProperties.cableTypeAssigned;
    this.autoSelect = cableProperties.autoSelect;
    this.maximumUtilisationPercent = cableProperties.maximumUtilisationPercent;
    this.maximumCurrentA = cableProperties.maximumCurrentA;
    this.maximumCurrentAPeriod = cableProperties.maximumCurrentAPeriod;
    this.earthLoopImpedance = cableProperties.earthLoopImpedance;
    this.cableGroup = cableProperties.cableGroup;
    this.cableGroupIsDefault = cableProperties.cableGroupIsDefault;
    this.numberOfPhases = cableProperties.numberOfPhases;
    this.numberOfPhasesIsDefault = cableProperties.numberOfPhasesIsDefault;
    this.numberOfPhasesOriginal = cableProperties.numberOfPhasesOriginal;
    this.styles = cableProperties.styles;
    this.overrideLength = cableProperties.overrideLength;
    this.groupedConnectionPoints = cableProperties.groupedConnectionPoints
      ? cableProperties.groupedConnectionPoints
      : [];
    this.feederFuseSize = cableProperties.feederFuseSize;
    this.hasFuseMaxCurrentWarning = cableProperties.hasFuseMaxCurrentWarning;
    this.isFirstLeg = cableProperties.isFirstLeg;
    this.hasFuseSize = cableProperties.hasFuseSize;
    this.feederNumber = cableProperties.feederNumber;
    this.ducting = cableProperties.ducting;
    this.ratingType = cableProperties.ratingType;
    this.AllmaximumUtilisationPercent = cableProperties.AllmaximumUtilisationPercent;
    this.AllmaximumCurrentPhase = cableProperties.AllmaximumCurrentPhase;
    this.AllmaximumCurrentA = cableProperties.AllmaximumCurrentA;
    this.AllmaximumCurrentAPeriod = cableProperties.AllmaximumCurrentAPeriod;
    this.AllminimumCurrentA = cableProperties.AllminimumCurrentA;
    this.AllmaximumCurrentAPhase1 = cableProperties.AllmaximumCurrentAPhase1;
    this.AllmaximumCurrentAPhase2 = cableProperties.AllmaximumCurrentAPhase2;
    this.AllmaximumCurrentAPhase3 = cableProperties.AllmaximumCurrentAPhase3;
    this.wintermaximumCurrentA = cableProperties.wintermaximumCurrentA;
    this.wintermaximumCurrentAPeriod = cableProperties.wintermaximumCurrentAPeriod;
    this.wintermaximumUtilisationPercent = cableProperties.wintermaximumUtilisationPercent;
    this.wintermaximumCurrentPhase = cableProperties.wintermaximumCurrentPhase;
    this.winterminimumCurrentA = cableProperties.winterminimumCurrentA;
    this.wintermaximumCurrentAPhase1 = cableProperties.wintermaximumCurrentAPhase1;
    this.wintermaximumCurrentAPhase2 = cableProperties.wintermaximumCurrentAPhase2;
    this.wintermaximumCurrentAPhase3 = cableProperties.wintermaximumCurrentAPhase3;
    this.summermaximumCurrentA = cableProperties.summermaximumCurrentA;
    this.summermaximumCurrentAPeriod = cableProperties.summermaximumCurrentAPeriod;
    this.summermaximumUtilisationPercent = cableProperties.summermaximumUtilisationPercent;
    this.summermaximumCurrentPhase = cableProperties.summermaximumCurrentPhase;
    this.summerminimumCurrentA = cableProperties.summerminimumCurrentA;
    this.summermaximumCurrentAPhase1 = cableProperties.summermaximumCurrentAPhase1;
    this.summermaximumCurrentAPhase2 = cableProperties.summermaximumCurrentAPhase2;
    this.summermaximumCurrentAPhase3 = cableProperties.summermaximumCurrentAPhase3;
    this.nearNodeNumber = cableProperties.nearNodeNumber;
    this.farNodeNumber = cableProperties.farNodeNumber;
    this.fuseSelection = cableProperties.fuseSelection;
    this.wpdId = cableProperties.wpdId;
    this.annotation = cableProperties.annotation;
    this.status = cableProperties.status;
    this.groundTypeOverrides = cableProperties.groundTypeOverrides;
    this.cascadingRAG = cableProperties.cascadingRAG;
    this.nonCascadingRAG = cableProperties.nonCascadingRAG;
    this.overrideGroundType = cableProperties.overrideGroundType;
    this.wintermaximumServiceCableDemandKVA = cableProperties.wintermaximumServiceCableDemandKVA;
    this.summermaximumServiceCableDemandKVA = cableProperties.summermaximumServiceCableDemandKVA;
    this.AllmaximumServiceCableDemandKVA = cableProperties.AllmaximumServiceCableDemandKVA;
    this.fusewayStatus = cableProperties.fusewayStatus;
    this.rootTransformerId = cableProperties.rootTransformerId;
    this.groundTypeOverridesIsDefault = cableProperties.groundTypeOverridesIsDefault;
  }

  [key: string]: any;
  geometry: LatLng[];
  startAssetId: string;
  endAssetId: string;
  consumersDownstream: number;
  consumersUpstream: number;
  actualPhasesAvailable: number;
  length: number;
  cableType: string;
  cableTypeIsDefault: Boolean;
  cableTypeOriginal: string;
  cableTypeAssigned: string;
  autoSelect: boolean;
  maximumUtilisationPercent: number;
  maximumCurrentA: number;
  maximumCurrentAPeriod: number;
  earthLoopImpedance: number;
  cableGroup: string;
  cableGroupIsDefault: Boolean;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  numberOfPhasesOriginal: string;
  overrideLength: number;
  groupedConnectionPoints: GroupedConnectionPoint[];
  feederFuseSize: number;
  hasFuseMaxCurrentWarning: Boolean;
  isFirstLeg: boolean;
  hasFuseSize: boolean;
  feederNumber: number | null;
  ducting: boolean;
  ratingType: string;
  AllmaximumUtilisationPercent: number;
  AllmaximumCurrentPhase: number;
  AllmaximumCurrentA: number;
  AllmaximumCurrentAPeriod: number;
  AllminimumCurrentA: number;
  AllmaximumCurrentAPhase1: number;
  AllmaximumCurrentAPhase2: number;
  AllmaximumCurrentAPhase3: number;
  wintermaximumCurrentA: number;
  wintermaximumCurrentAPeriod: number;
  wintermaximumUtilisationPercent: number;
  wintermaximumCurrentPhase: number;
  winterminimumCurrentA: number;
  wintermaximumCurrentAPhase1: number;
  wintermaximumCurrentAPhase2: number;
  wintermaximumCurrentAPhase3: number;
  summermaximumCurrentA: number;
  summermaximumCurrentAPeriod: number;
  summermaximumUtilisationPercent: number;
  summermaximumCurrentPhase: number;
  summerminimumCurrentA: number;
  summermaximumCurrentAPhase1: number;
  summermaximumCurrentAPhase2: number;
  summermaximumCurrentAPhase3: number;
  nearNodeNumber: number;
  farNodeNumber: number;
  fuseSelection: string;
  wpdId: string;
  annotation: string;
  status: string;
  groundTypeOverrides: any[];
  cascadingRAG: string;
  nonCascadingRAG: string;
  overrideGroundType: any[];
  wintermaximumServiceCableDemandKVA: number;
  summermaximumServiceCableDemandKVA: number;
  AllmaximumServiceCableDemandKVA: number;
  fusewayStatus: string;
  rootTransformerId: string;
  groundTypeOverridesIsDefault: Boolean;
}
