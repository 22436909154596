import L from "leaflet";
import { useEffect, useRef, useContext } from "react";
import { Polygon } from "react-leaflet";
import ImageOverlayRotated from "./ImageOverlayRotated";
import { ToolContext } from "../context/ToolContext";
import * as tab from "../constants/tabs";

import { featureCollection, point } from "@turf/helpers";
import distance from "@turf/distance";
import destination from "@turf/destination";
import center from "@turf/center";
import flip from "@turf/flip";
import bearing from "@turf/bearing";

const units = {
  units: "meters",
};

const SitePlanImport = ({ sitePlan, selectedObject, onClick }) => {
  const shapeRef = useRef(null);
  const imageOverlayRef = useRef(null);

  const { toolState, setToolState } = useContext(ToolContext);
  const { sitePlans, updateSitePlan } = toolState;

  const enableTransform = (_shape) => {
    if (_shape != null) {
      if (_shape.transform) {
        _shape.transform.enable();
        _shape.dragging.enable();
        _shape.transform.setOptions({
          uniformScaling: sitePlan.uniformScaling,
          // edge handlers
          handlerOptions: {
            radius: 6,
            fillColor: "#ffffff",
            color: "#3388ff",
            fillOpacity: 1,
            weight: 2,
            opacity: 0.7,
            setCursor: true,
            renderer: L.svg(),
          },

          // rectangle
          boundsOptions: {
            weight: 2,
            opacity: 1,
            dashArray: [6, 6],
            fill: false,
            noClip: true,
            renderer: L.svg(),
          },

          // rotation handler
          rotateHandleOptions: {
            weight: 1,
            opacity: 1,
            setCursor: true,
            renderer: L.svg(),
          },
        });
        _shape._setLatLngs(sitePlan.positions);
      }
    }
  };

  useEffect(() => {
    const shape = shapeRef.current;
    if (shape != null) {
      enableTransform(shape);

      shape.on("transformed", (e) => {
        onTransformed(e);
      });
      shape.on("dragstart", () => {
        shape.setStyle({
          opacity: 1,
        });
      });
      shape.on("dragend", () => {
        shape.setStyle({
          opacity: 0,
        });
      });
    }
  }, [shapeRef]);

  useEffect(() => {
    const shape = shapeRef.current;
    if (shape != null) {
      enableTransform(shape);
    }
  }, [sitePlan.positions]);

  useEffect(() => {
    const shape = shapeRef.current;
    const imageOverlay = imageOverlayRef.current;

    if (sitePlan.opacity !== imageOverlay.options.opacity) {
      if (shape != null && imageOverlay != null) {
        enableTransform(shape);

        imageOverlay.setStyle({
          opacity: sitePlan.opacity,
        });
      }
    } else {
      if (!updateSitePlan) return;
      if (imageOverlay != null) {
        const updatedPositions = updatePositions(imageOverlay);

        imageOverlay.reposition(updatedPositions[0], updatedPositions[1], updatedPositions[3]);

        const _toolState = { ...toolState };
        const _sitePlans = sitePlans;
        _sitePlans.find((s) => s.id === sitePlan.id).positions = updatedPositions;
        _toolState.sitePlans = _sitePlans;
        setToolState(_toolState);
      }
    }
  }, [updateSitePlan]);

  const updatePositions = (imageOverlay) => {
    const topLeft = point([imageOverlay._topLeft.lng, imageOverlay._topLeft.lat]);
    const bottomLeft = point([imageOverlay._bottomLeft.lng, imageOverlay._bottomLeft.lat]);
    const topRight = point([imageOverlay._topRight.lng, imageOverlay._topRight.lat]);

    const _center = center(featureCollection([bottomLeft, topRight]));
    const _bearing = bearing(topLeft, topRight) - 90;

    const width = sitePlan.width / 2;
    const height = sitePlan.height / 2;

    const left = destination(_center, width, -90 + _bearing, units);
    const right = destination(_center, width, 90 + _bearing, units);

    const tL = flip(destination(left, height, 0 + _bearing, units));
    const bL = flip(destination(left, height, 180 + _bearing, units));
    const tR = flip(destination(right, height, 0 + _bearing, units));
    const bR = flip(destination(right, height, 180 + _bearing, units));

    const _newPositions = [];

    _newPositions.push(tL.geometry.coordinates);
    _newPositions.push(tR.geometry.coordinates);
    _newPositions.push(bR.geometry.coordinates);
    _newPositions.push(bL.geometry.coordinates);

    return _newPositions;
  };

  const getDistance = (start, end, units) => {
    var startPt = flip(point(start));
    var endPt = flip(point(end));

    return distance(startPt, endPt, units);
  };

  const onTransformed = (e) => {
    const imageOverlay = imageOverlayRef.current;
    if (imageOverlay != null) {
      const newPositions = e.target.getLatLngs().map((m) => m.map((n) => [n.lat, n.lng]));

      imageOverlay.reposition(newPositions[0][0], newPositions[0][1], newPositions[0][3]);

      const _toolState = { ...toolState };
      const _sitePlans = sitePlans;
      let _sitePlan = _sitePlans.find((s) => s.id === sitePlan.id);
      _sitePlan.positions = newPositions[0];
      _sitePlan.width = getDistance(newPositions[0][0], newPositions[0][1], units);
      _sitePlan.height = getDistance(newPositions[0][0], newPositions[0][3], units);

      _toolState.sitePlans = _sitePlans;
      setToolState(_toolState);
    }
  };

  const selectSitePlan = (sitePlan) => {
    const _toolState = { ...toolState };
    _toolState.activeTab = tab.PROPERTIES;
    setToolState(_toolState);
    onClick(sitePlan);
  };

  return (
    <>
      <Polygon
        ref={shapeRef}
        positions={sitePlan.positions}
        transform={selectedObject}
        draggable={selectedObject}
        pathOptions={{
          weight: 2,
          dashArray: "6, 6",
          opacity: 0,
          fillOpacity: 0,
        }}
        eventHandlers={{
          click: () => {
            selectSitePlan(sitePlan);
          },
        }}
      />
      <ImageOverlayRotated
        ref={imageOverlayRef}
        url={sitePlan.image}
        topLeft={sitePlan.positions[0]}
        topRight={sitePlan.positions[1]}
        bottomLeft={sitePlan.positions[3]}
        opacity={sitePlan.opacity}
        interactive={true}
      />
    </>
  );
};

export default SitePlanImport;
