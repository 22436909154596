// @flow

import getAssetStyleInfo from "../../context/AssetStyleInfo";
import ConductingEquipment from "../cim/conductingEquipment";
import { Node } from "./node";
import { NodeProperties } from "./nodeProperties";
import { getLatLngFromWktPoint } from "./geometryWktConverter";
import * as statusConstants from "../../constants/status";

export function createNodeFromConductingEquipment(
  reference: any,
  conductingEquipment: ConductingEquipment,
): Node {
  const nodeProperties = new NodeProperties();
  nodeProperties.annotation = conductingEquipment.getPropertyValue("annotation");
  const geometryString = conductingEquipment.getPropertyValue("geometry");
  nodeProperties.geometry = getLatLngFromWktPoint(geometryString);
  nodeProperties.maxVoltDropPercent = conductingEquipment.getPropertyValue("maxVoltDropPercent");
  nodeProperties.maxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("maxVoltDropPercentPassResult") === "true";
  nodeProperties.maxVoltRiseTotal = conductingEquipment.getPropertyValue("maxVoltRiseTotal");
  nodeProperties.maxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("maxVoltRiseGeneration");
  nodeProperties.maxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("maxVoltRiseUnbalance");
  nodeProperties.earthLoopImpedance =
    conductingEquipment.getPropertyValue("earthLoopImpedance") == null
      ? null
      : Number(conductingEquipment.getPropertyValue("earthLoopImpedance"));
  nodeProperties.threePhaseFaultCurrent = (
    parseFloat(conductingEquipment.getPropertyValue("threePhaseFaultCurrent")) / 1000
  ).toFixed(3);

  nodeProperties.phaseToPhaseFaultCurrent = (
    parseFloat(conductingEquipment.getPropertyValue("phaseToPhaseFaultCurrent")) / 1000
  ).toFixed(3);

  nodeProperties.singlePhaseToEarthFaultCurrent = (
    parseFloat(conductingEquipment.getPropertyValue("singlePhaseToEarthFaultCurrent")) / 1000
  ).toFixed(3);

  nodeProperties.hasFuseWarning = conductingEquipment.getPropertyValue("hasFuseWarning") === "true";
  nodeProperties.flickerPassResult =
    conductingEquipment.getPropertyValue("flickerPassResult") === "true";
  nodeProperties.flickerDeltaVoltage = conductingEquipment.getPropertyValue("flickerDeltaVoltage");

  nodeProperties.flickerP28Limit = conductingEquipment.getPropertyValue("flickerP28Limit");

  nodeProperties.flickerStartingPassResult =
    conductingEquipment.getPropertyValue("flickerStartingPassResult") === "true";
  nodeProperties.flickerStartingDeltaVoltage = conductingEquipment.getPropertyValue(
    "flickerStartingDeltaVoltage",
  );

  nodeProperties.styles = getAssetStyleInfo(conductingEquipment.getPropertyValue("class"));

  nodeProperties.impedanceLoopOperating =
    conductingEquipment.getPropertyValue("impedanceLoopOperating");

  nodeProperties.impedanceLoopOperatingPassResult =
    conductingEquipment.getPropertyValue("impedanceLoopOperatingPassResult") === "true";

  nodeProperties.impedanceSourceLoopOperating = conductingEquipment.getPropertyValue(
    "impedanceSourceLoopOperating",
  );

  nodeProperties.impedanceSourceLoopOperatingPassResult =
    conductingEquipment.getPropertyValue("impedanceSourceLoopOperatingPassResult") === "true";

  nodeProperties.impedanceLoopFault = conductingEquipment.getPropertyValue("impedanceLoopFault");
  nodeProperties.impedancePhaseOperating =
    conductingEquipment.getPropertyValue("impedancePhaseOperating");
  nodeProperties.impedanceSourcePhaseOperating = conductingEquipment.getPropertyValue(
    "impedanceSourcePhaseOperating",
  );
  nodeProperties.impedanceSourcePhasePhaseOperating = conductingEquipment.getPropertyValue(
    "impedanceSourcePhasePhaseOperating",
  );
  nodeProperties.impedancePhaseFault = conductingEquipment.getPropertyValue("impedancePhaseFault");
  nodeProperties.resistanceLoopOperating =
    conductingEquipment.getPropertyValue("resistanceLoopOperating");
  nodeProperties.resistanceSourceLoopOperating = conductingEquipment.getPropertyValue(
    "resistanceSourceLoopOperating",
  );
  nodeProperties.resistanceLoopFault = conductingEquipment.getPropertyValue("resistanceLoopFault");
  nodeProperties.resistancePhaseOperating = conductingEquipment.getPropertyValue(
    "resistancePhaseOperating",
  );
  nodeProperties.resistanceSourcePhaseOperating = conductingEquipment.getPropertyValue(
    "resistanceSourcePhaseOperating",
  );
  nodeProperties.resistanceSourcePhasePhaseOperating = conductingEquipment.getPropertyValue(
    "resistanceSourcePhasePhaseOperating",
  );
  nodeProperties.resistancePhaseFault =
    conductingEquipment.getPropertyValue("resistancePhaseFault");
  nodeProperties.reactanceLoopOperating =
    conductingEquipment.getPropertyValue("reactanceLoopOperating");
  nodeProperties.reactanceSourceLoopOperating = conductingEquipment.getPropertyValue(
    "reactanceSourceLoopOperating",
  );
  nodeProperties.reactanceLoopFault = conductingEquipment.getPropertyValue("reactanceLoopFault");
  nodeProperties.reactancePhaseOperating =
    conductingEquipment.getPropertyValue("reactancePhaseOperating");
  nodeProperties.reactanceSourcePhaseOperating = conductingEquipment.getPropertyValue(
    "reactanceSourcePhaseOperating",
  );
  nodeProperties.reactanceSourcePhasePhaseOperating = conductingEquipment.getPropertyValue(
    "reactanceSourcePhasePhaseOperating",
  );
  nodeProperties.reactancePhaseFault = conductingEquipment.getPropertyValue("reactancePhaseFault");

  nodeProperties.AllmaxVoltDropPercent =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercent");
  nodeProperties.AllmaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "AllmaxVoltDropPercentPeriod",
  );
  nodeProperties.AllmaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercentPassResult") === "true";
  nodeProperties.AllmaxVoltDropPhase = conductingEquipment.getPropertyValue("AllmaxVoltDropPhase");
  nodeProperties.AllmaxVoltRiseTotal = conductingEquipment.getPropertyValue("AllmaxVoltRiseTotal");
  nodeProperties.AllmaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "AllmaxVoltRiseGeneration",
  );
  nodeProperties.AllmaxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseUnbalance");
  nodeProperties.AllmaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "AllmaxVoltRisePercentPeriod",
  );
  nodeProperties.AllmaxVoltRisePhase = conductingEquipment.getPropertyValue("AllmaxVoltRisePhase");
  nodeProperties.AllmaxVoltage = conductingEquipment.getPropertyValue("AllmaxVoltage");
  nodeProperties.AllmaxVoltagePhase1 = conductingEquipment.getPropertyValue("AllmaxVoltagePhase1");
  nodeProperties.AllmaxVoltagePhase2 = conductingEquipment.getPropertyValue("AllmaxVoltagePhase2");
  nodeProperties.AllmaxVoltagePhase3 = conductingEquipment.getPropertyValue("AllmaxVoltagePhase3");
  nodeProperties.AllminVoltage = conductingEquipment.getPropertyValue("AllminVoltage");
  nodeProperties.AllminVoltagePhase1 = conductingEquipment.getPropertyValue("AllminVoltagePhase1");
  nodeProperties.AllminVoltagePhase2 = conductingEquipment.getPropertyValue("AllminVoltagePhase2");
  nodeProperties.AllminVoltagePhase3 = conductingEquipment.getPropertyValue("AllminVoltagePhase3");
  nodeProperties.wintermaxVoltDropPercent = conductingEquipment.getPropertyValue(
    "wintermaxVoltDropPercent",
  );
  nodeProperties.wintermaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "wintermaxVoltDropPercentPeriod",
  );
  nodeProperties.wintermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPercentPassResult") === "true";
  nodeProperties.wintermaxVoltDropPhase =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPhase");
  nodeProperties.wintermaxVoltRisePercent = conductingEquipment.getPropertyValue(
    "wintermaxVoltRisePercent",
  );
  nodeProperties.wintermaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("wintermaxVoltRiseTotal");
  nodeProperties.wintermaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "wintermaxVoltRiseGeneration",
  );
  nodeProperties.wintermaxVoltRiseUnbalance = conductingEquipment.getPropertyValue(
    "wintermaxVoltRiseUnbalance",
  );
  nodeProperties.wintermaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "wintermaxVoltRisePercentPeriod",
  );
  nodeProperties.wintermaxVoltRisePhase =
    conductingEquipment.getPropertyValue("wintermaxVoltRisePhase");
  nodeProperties.wintermaxVoltage = conductingEquipment.getPropertyValue("wintermaxVoltage");
  nodeProperties.wintermaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase1");
  nodeProperties.wintermaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase2");
  nodeProperties.wintermaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase3");
  nodeProperties.winterminVoltage = conductingEquipment.getPropertyValue("winterminVoltage");
  nodeProperties.winterminVoltagePhase1 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase1");
  nodeProperties.winterminVoltagePhase2 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase2");
  nodeProperties.winterminVoltagePhase3 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase3");
  nodeProperties.summermaxVoltDropPercent = conductingEquipment.getPropertyValue(
    "summermaxVoltDropPercent",
  );
  nodeProperties.summermaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "summermaxVoltDropPercentPeriod",
  );
  nodeProperties.summermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("summermaxVoltDropPercentPassResult") === "true";
  nodeProperties.summermaxVoltDropPhase =
    conductingEquipment.getPropertyValue("summermaxVoltDropPhase");
  nodeProperties.summermaxVoltRisePercent = conductingEquipment.getPropertyValue(
    "summermaxVoltRisePercent",
  );
  nodeProperties.summermaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("summermaxVoltRiseTotal");
  nodeProperties.summermaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "summermaxVoltRiseGeneration",
  );
  nodeProperties.summermaxVoltRiseUnbalance = conductingEquipment.getPropertyValue(
    "summermaxVoltRiseUnbalance",
  );
  nodeProperties.summermaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "summermaxVoltRisePercentPeriod",
  );
  nodeProperties.summermaxVoltRisePhase =
    conductingEquipment.getPropertyValue("summermaxVoltRisePhase");
  nodeProperties.summermaxVoltage = conductingEquipment.getPropertyValue("summermaxVoltage");
  nodeProperties.summermaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase1");
  nodeProperties.summermaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase2");
  nodeProperties.summermaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase3");
  nodeProperties.summerminVoltage = conductingEquipment.getPropertyValue("summerminVoltage");
  nodeProperties.summerminVoltagePhase1 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase1");
  nodeProperties.summerminVoltagePhase2 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase2");
  nodeProperties.summerminVoltagePhase3 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase3");
  nodeProperties.nodeNumber = conductingEquipment.getPropertyValue("nodeNumber");

  nodeProperties.isServiceLeaf = conductingEquipment.getPropertyValue("isServiceLeaf") === "true";

  nodeProperties.status =
    conductingEquipment.getPropertyValue("status") ?? statusConstants.EXISTING;
  nodeProperties.linkBox = JSON.parse(conductingEquipment.getPropertyValue("linkBox"));
  if (nodeProperties.linkBox && !nodeProperties.linkBox.status) {
    nodeProperties.linkBox.status = statusConstants.EXISTING;
  }
  nodeProperties.potEnds = JSON.parse(conductingEquipment.getPropertyValue("potEnds"));
  nodeProperties.overrideGroundType =
    conductingEquipment.getPropertyValue("overrideGroundType") ??
    reference.groundTypeOverrideDefaults.groundTypeOverride;
  nodeProperties.pole = JSON.parse(conductingEquipment.getPropertyValue("pole"));
  nodeProperties.poleHeightIsDefault =
    conductingEquipment.getPropertyValue("poleHeightIsDefault") === "true";
  nodeProperties.stayAngleIsDefault =
    conductingEquipment.getPropertyValue("stayAngleIsDefault") === "true";
  nodeProperties.poleTermLengthIsDefault =
    conductingEquipment.getPropertyValue("poleTermLengthIsDefault") === "true";
  nodeProperties.fuse = conductingEquipment.getPropertyValue("fuse");
  nodeProperties.phaseImpedance = conductingEquipment.getPropertyValue("phaseImpedance");
  nodeProperties.loopImpedance = conductingEquipment.getPropertyValue("loopImpedance");
  nodeProperties.rootTransformerId = conductingEquipment.getPropertyValue("rootTransformerId");
  nodeProperties.wpdId = conductingEquipment.getPropertyValue("wpdId");
  nodeProperties.fuseIsDefault = conductingEquipment.getPropertyValue("fuseIsDefault") === "true";

  return new Node(conductingEquipment.id, nodeProperties);
}
