import {
  updateCable,
  updateGroupedConnection,
  updateGroupedConnectionChild,
  updateGroupedConnectionPole,
} from "../../app/networkSlice";
import { transformerTypes } from "../../constants/groupUpdateTypes";
import * as statusConstants from "../../constants/status";

export const shouldShowLinkBoxStatusForm = (clientSettings, selected, ringfencedFiltered) => {
  if (!clientSettings.Features.ConfigurableLinkBoxEnabled) {
    return false;
  }
  const shouldShow = (type) => {
    switch (type) {
      case "Nodes":
      case "Node":
      case "Service End Node":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    return false;
  }
  return shouldShow(selected) && ringfencedFiltered.some((p) => p.linkBox);
};

export const shouldShowPotEndStatusForm = (clientSettings, selected, ringfencedFiltered) => {
  if (!clientSettings.Features.CostingEnabled) {
    return false;
  }
  const shouldShow = (type) => {
    switch (type) {
      case "Nodes":
      case "Node":
      case "Service End Node":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    return false;
  }
  return shouldShow(selected) && ringfencedFiltered.some((p) => p.potEnds?.length > 0);
};

export const shouldShowPoleStatusForm = (clientSettings, selected, ringfencedFiltered) => {
  if (!clientSettings.Features.CostingEnabled) {
    return false;
  }
  const shouldShow = (type) => {
    switch (type) {
      case "Nodes":
      case "Node":
      case "Service End Node":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    return false;
  }
  return shouldShow(selected) && ringfencedFiltered.some((p) => p.pole?.enabled);
};

export const shouldShowStatusForm = (clientSettings, selected, ringfencedTypes) => {
  if (!clientSettings.Features.CostingEnabled) {
    return false;
  }
  const shouldShow = (type) => {
    switch (type) {
      case "Properties":
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "Conductors":
      case "Mains Overhead Line":
      case "Mains Underground Cable":
      case "Service Overhead Line":
      case "Service Underground Cable":
      case "LCT":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
      case "Connection":
      case "Grouped Connection":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    const isNotAllowed = ringfencedTypes.some(
      (p) =>
        !shouldShow(p.name) &&
        !["Node", "Service End Node"].includes(p.name) &&
        !transformerTypes.includes(p.name),
    );
    return !isNotAllowed;
  }
  return shouldShow(selected);
};

export const reviewStatusChange = (
  ringfencedFiltered,
  cableType,
  status,
  ohWhitelist,
  ugWhitelist,
) => {
  const warnings = {};
  if (cableType) {
    return [];
  }
  ringfencedFiltered.forEach((p) => {
    switch (p.styles.name) {
      case "Overhead Line":
      case "Underground Cable":
        if (status === statusConstants.NEW || status === statusConstants.REPLACEMENT) {
          const whitelist = p.cableGroup.split("-")[1] === "overhead" ? ohWhitelist : ugWhitelist;
          if (!whitelist.includes(p.cableType) && p.cableType !== "auto") {
            const message = `The cable type for {0} ${
              p.cableGroup.split("-")[1]
            } cable{1} will be set to Auto`;
            warnings[message] = (warnings[message] || 0) + 1;
          }
        }
        break;
    }
  });
  return Object.entries(warnings).map(([name, count]) => ({ name, count }));
};

export const applyStatusChange = (
  ringfencedFiltered,
  dispatchRedux,
  status,
  ohWhitelist,
  ugWhitelist,
  groupedConnections,
) => {
  ringfencedFiltered.forEach((p) => {
    if (transformerTypes.includes(p.styles.name)) {
      return;
    }

    if (p.groupedConnectionPoints?.length > 0) {
      p.groupedConnectionPoints.forEach((q) => {
        if (q.status !== status) {
          dispatchRedux(
            updateGroupedConnectionChild({
              id: p.id,
              childUpdate: { id: q.id, name: "status", value: status },
              isGroupUpdate: false,
            }),
          );
        }
      });
    }

    const update = {
      id: p.id,
      name: "status",
      value: status,
    };

    switch (p.styles.name) {
      case "Overhead Line":
      case "Underground Cable":
        dispatchRedux(updateCable(update));

        if (p.cableGroup === "mains-overhead") {
          const connectedPoles = groupedConnections.filter(
            (gc) => (gc.id === p.startAssetId || gc.id === p.endAssetId) && gc.pole,
          );

          connectedPoles.forEach((connectedPole) => {
            dispatchRedux(
              updateGroupedConnectionPole({
                id: connectedPole.id,
                name: "status",
                value: status,
              }),
            );
          });
        }

        if (status === statusConstants.NEW || status === statusConstants.REPLACEMENT) {
          const whitelist = p.cableGroup.split("-")[1] === "overhead" ? ohWhitelist : ugWhitelist;
          if (!whitelist.includes(p.cableType)) {
            dispatchRedux(updateCable({ id: p.id, name: "autoSelect", value: true }));
            dispatchRedux(updateCable({ id: p.id, name: "cableType", value: "auto" }));
          }
        }
        break;
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
      case "Grouped Connection":
        dispatchRedux(updateGroupedConnection(update));
        break;
      case "Node":
      case "Service End Node":
        if (p.pole && p.pole !== status) {
          dispatchRedux(
            updateGroupedConnection({
              id: p.id,
              name: "pole",
              value: { ...p.pole, status },
            }),
          );
        }
        if (p.potEnds && p.potEnds.some((q) => q.status !== status)) {
          dispatchRedux(
            updateGroupedConnection({
              id: p.id,
              name: "potEnds",
              value: p.potEnds.map((q) => ({ ...q, status })),
            }),
          );
        }
        if (p.linkBox && p.linkBox !== status) {
          dispatchRedux(
            updateGroupedConnection({
              id: p.id,
              name: "linkBox",
              value: { ...p.linkBox, status },
            }),
          );
        }
        break;
    }
  });
};
