import { useContext } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid } from "uuid";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import * as statusConstants from "../constants/status";
import { updateGroupedConnection } from "../app/networkSlice";

const PotEndSelector = () => {
  const { toolState } = useContext(ToolContext);
  const { formState, dispatch } = useContext(FormContext);
  const { groupedConnectionProperties } = formState;
  const { potEnds } = groupedConnectionProperties;
  const { clickedAsset } = toolState;

  const dispatchRedux = useDispatch();

  const selectList = [{ name: "Pot End" }];

  const addPotEnd = () => {
    const potEnd = { id: uuid(), status: statusConstants.NEW };
    const newValue = potEnds ? [...potEnds, potEnd] : [potEnd];
    updateNode(newValue);
  };

  const removePotEnd = (potEnd) => {
    const newValue = potEnds.filter((x) => x !== potEnd);
    updateNode(newValue);
  };

  const updateStatus = (e, index) => {
    const updatedPotEnds = potEnds.map((p, i) => {
      if (i === index) {
        return { ...p, status: e.target.value };
      }
      return p;
    });

    updateNode(updatedPotEnds);
  };

  const updateNode = (value) => {
    dispatch({
      form: "groupedConnectionProperties",
      field: "potEnds",
      value: value,
      type: "UPDATE_FIELD",
    });
    if (clickedAsset) {
      dispatchRedux(updateGroupedConnection({ id: clickedAsset.id, name: "potEnds", value }));
    }
  };

  return (
    <div
      className="bg-white-alpha"
      style={{
        padding: ".75rem",
        margin: ".75rem 0",
        marginBottom: "1rem",
      }}
    >
      <h6
        className="text-light"
        style={{
          fontSize: ".75rem",
          textTransform: "uppercase",
          fontWeight: 200,
        }}
      >
        Add Pot End
      </h6>
      <div>
        <div className="text-light d-flex">
          <Input type="select" name="potEnds" className="w-100">
            {selectList.map((item) => (
              <option key={item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </Input>
          <Button id="add" size="sm" onClick={addPotEnd} color={"success"} className="ml-2">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
        {potEnds &&
          potEnds.map((potEnd, index) => (
            <div className="pt-3 ml-3 d-flex row" key={potEnd.id}>
              <Label className="col-12 pl-0" for="status">{`Pot End ${
                index + 1
              }: New/Existing`}</Label>
              <Input
                type="select"
                name="status"
                id="status"
                value={potEnd.status}
                className="col-8"
                onChange={(e) => updateStatus(e, index)}
              >
                <option value={statusConstants.NEW}>New</option>
                <option value={statusConstants.EXISTING}>Existing</option>
                <option value={statusConstants.REPLACEMENT}>Replacement</option>
              </Input>
              <Button
                id="remove"
                size="sm"
                color={potEnds ? "danger" : "success"}
                className="ml-2"
                onClick={() => removePotEnd(potEnd)}
              >
                <FontAwesomeIcon icon={potEnds ? faMinus : faPlus} />
              </Button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PotEndSelector;
