import { useState, useEffect, useContext, Fragment } from "react";
import { useSelector } from "react-redux";
import NewWindow from "react-new-window";
import { Container, Nav, NavItem, NavLink } from "reactstrap";
import domtoimage from "dom-to-image";
import EALogo from "./EALogo";
import { FormContext } from "../context/FormContext";
import ResultReport from "./ResultReport";
import { groupBy } from "../utils/groupBy";
import "./ResultReport.css";

const ResultReportWindow = ({ network, onUnload }) => {
  const [screenshot, setScreenshot] = useState("");
  const { REACT_APP_THEME } = process.env;
  const { formState } = useContext(FormContext);

  const { clientSettings } = formState;

  const welders = useSelector((state) => state.network.present.welders);
  const motors = useSelector((state) => state.network.present.motors);
  const transformers = useSelector((state) => state.network.present.transformers);
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);
  const cables = useSelector((state) => state.network.present.cables);

  const [activeReportId, setActiveReportId] = useState();
  const [reportIds, setReportIds] = useState([]);

  const networkWelders = groupBy(welders, "rootTransformerId");
  const networkMotors = groupBy(motors, "rootTransformerId");
  const networkTransformers = groupBy(transformers, "id");
  const networkGroupedConnections = groupBy(groupedConnections, "rootTransformerId");
  const networkCables = groupBy(cables, "rootTransformerId");

  useEffect(() => {
    saveScreenshot();
    const rootTransformerId = transformers[0].id;
    const rootTransformerIds = transformers.map((t) => t.id);
    setActiveReportId(rootTransformerId);
    setReportIds(rootTransformerIds);
  }, []);

  const saveScreenshot = () => {
    const printArea = document.getElementById("map");

    domtoimage
      .toPng(printArea, { height: 940, width: 1920 })
      .then((dataURL) => {
        setScreenshot(dataURL);
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
      });
  };

  const toggle = (tab) => {
    if (activeReportId !== tab) {
      setActiveReportId(tab);
    }
  };

  return (
    <NewWindow
      network={network}
      screenshot={screenshot}
      features={{ width: 1200, height: 600 }}
      onUnload={() => onUnload(false)}
      id="popup"
    >
      <Container className="report">
        <div className="mb-4">
          <EALogo
            theme={REACT_APP_THEME}
            displayName={clientSettings.General.DisplayName}
            color="#1c1f3e"
            logoColor="#fff"
            logoBgColor="#fab274"
            width="1200"
            height="120"
          />
        </div>
        {screenshot && (
          <div style={{ marginBottom: "3rem" }}>
            <img id="thumbnail" src={screenshot} width="100%" />
          </div>
        )}
        <h2 className="mb-4" style={{ paddingLeft: ".3rem" }}>
          Network Data
        </h2>
      </Container>
      <div className="screen-report">
        <Container className="report">
          <Nav tabs style={{ fontSize: "1rem" }}>
            {Object.entries(networkTransformers).map(([k, v], i) => (
              <NavItem>
                <NavLink
                  className={activeReportId === k ? "active" : ""}
                  onClick={() => {
                    toggle(k);
                  }}
                >
                  {v[0].annotation || v[0].substationId || `Transformer ${i + 1}`}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Container>
        {activeReportId && (
          <ResultReport
            reportId={activeReportId}
            networkTransformers={networkTransformers}
            networkGroupedConnections={networkGroupedConnections}
            networkCables={networkCables}
            networkMotors={networkMotors}
            networkWelders={networkWelders}
          />
        )}
      </div>
      <div className="print-report">
        {reportIds.map((reportId) => (
          <Fragment key={reportId}>
            <ResultReport
              reportId={reportId}
              networkTransformers={networkTransformers}
              networkGroupedConnections={networkGroupedConnections}
              networkCables={networkCables}
              networkMotors={networkMotors}
              networkWelders={networkWelders}
            />
          </Fragment>
        ))}
      </div>
    </NewWindow>
  );
};

export default ResultReportWindow;
