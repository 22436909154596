import React, { useContext, useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import "leaflet/dist/leaflet.css";
import { FormContext } from "../context/FormContext";
import ConnectLV from "./ConnectLV";
import { getReference, authorise, getClientSettings } from "../services/apiService";
import useMsalEvents from "../useMsalEvents";
import { loginRequest } from "../authConfig";
import { store } from "../app/store";
import { updateMaxHistory } from "../app/undoable";

const MsalSwitcher = () => {
  const [authorised, setAuthorised] = useState(false);
  const [settings, setSettings] = useState();
  const { instance, accounts } = useMsal();
  const { REACT_APP_B2B, REACT_APP_B2C } = process.env;
  const b2x = REACT_APP_B2B === "true" || REACT_APP_B2C === "true";
  useMsalEvents();

  useEffect(() => {
    if (accounts.length > 0 || !b2x) {
      const account = accounts[0];
      const replaceClientSettings = async () => {
        const clientSettings = await getClientSettings(account);
        document.title = clientSettings.General.DisplayName;
        const attribute = document.querySelector("html");
        attribute.style.setProperty(
          "--newColourblindMarkerColour",
          clientSettings.General.CostingStylingNewAssetColourBlindColour,
        );
        attribute.style.setProperty(
          "--newMarkerColour",
          clientSettings.General.CostingStylingNewAssetColour,
        );
        attribute.style.setProperty(
          "--replacementColourblindMarkerColour",
          clientSettings.General.CostingStylingReplacementAssetColourBlindColour,
        );
        attribute.style.setProperty(
          "--replacementMarkerColour",
          clientSettings.General.CostingStylingReplacementAssetColour,
        );
        dispatch({
          form: "clientSettings",
          obj: clientSettings,
          type: "REPLACE_STATE",
        });
        const reference = await getReference(account);
        dispatch({
          form: "reference",
          obj: reference,
          type: "REPLACE_STATE",
        });
        store.dispatch(updateMaxHistory(clientSettings.General.MaxHistory));
        setSettings(clientSettings);
        b2x && authoriseUser(account);
      };
      replaceClientSettings();
    }
  }, [accounts]);

  const authoriseUser = (account) => {
    authorise(account)
      .then((response) => {
        if (response.data.messages) {
          console.log(response.data);
          setAuthorised(false);
          return;
        }
        console.log(response);
        setAuthorised(true);
      })
      .catch((error) => {
        console.log(error);
        setAuthorised(false);
        instance.logoutRedirect();
      });
  };

  const { dispatch } = useContext(FormContext);

  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      {b2x ? (
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
        >
          {settings && authorised && <ConnectLV />}
        </MsalAuthenticationTemplate>
      ) : (
        settings && <ConnectLV />
      )}
    </>
  );
};

export default MsalSwitcher;
