import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { Button, Modal, ModalBody, Table } from "reactstrap";
import { listConfigs } from "../services/apiService";
import { groupBy } from "../utils/groupBy";
import { FormContext } from "../context/FormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const ConfigurationSelection = ({ selectConfig, cancel }) => {
  const { accounts } = useMsal();
  const { REACT_APP_THEME } = process.env;

  const { formState } = useContext(FormContext);
  const { clientSettings } = formState;

  const [isLoading, setIsLoading] = useState(true);
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    listConfigs(accounts[0]).then((p) => {
      setVersions(p ?? []);
      setIsLoading(false);
    });
  }, []);

  return (
    <Modal isOpen={true} backdrop="static" keyboard={false} container="body">
      <ModalBody className="d-flex justify-content-between">
        <div className="container">
          <div className="row">
            <span>Which configuration would you like to use for this network?</span>
          </div>
          {isLoading && (
            <div className="row justify-content-center mt-3">
              <icon className="spinner icon-spinner-solid" />
            </div>
          )}
          {!isLoading && versions.length > 0 && (
            <div className="row justify-content-center mt-3">
              <div className="col-auto">
                <Table
                  id="version-table"
                  className={`table-sm table-custom table-custom-${REACT_APP_THEME} table-sortable w-auto`}
                >
                  <thead>
                    <tr className="mb-4">
                      <th>Version</th>
                      <th>Release Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(groupBy(versions, "clientName")).map((row) => (
                      <tr
                        key={`${row[0].clientName} ${row[0].versionNumber}`}
                        className={
                          row[0].clientName ===
                          clientSettings.General.DefaultWinDebutConfigurationClient
                            ? "active"
                            : ""
                        }
                      >
                        <td>
                          {row[0].clientName} v{row[0].versionNumber}
                          {row[0].clientName ===
                            clientSettings.General.DefaultWinDebutConfigurationClient && (
                            <span className="badge badge-success">Default</span>
                          )}
                        </td>
                        <td>{row[0].releaseDate}</td>
                        <td>
                          <Button
                            size="sm"
                            color="success"
                            className="ml-2"
                            onClick={() => selectConfig(row[0])}
                          >
                            Use
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          {!isLoading && versions.length === 0 && (
            <div className="row text-danger">
              <span>
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1 ml-3 mt-3" />
                There was a problem getting the configurations
              </span>
            </div>
          )}
          <Button
            style={{ marginLeft: 10, height: 35 }}
            size="sm"
            color="info"
            className="ml-2 float-right"
            onClick={() => cancel()}
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfigurationSelection;
