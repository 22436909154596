import { useContext, useState } from "react";
import { Pane, Marker, Polyline, useMap } from "react-leaflet";
import L from "leaflet";
import { v4 as uuid } from "uuid";
import "../components/DetectTooltip.css";
import { FormContext } from "../context/FormContext";
import { pinMarker } from "../utils/pinMarker";
import * as color from "../constants/colors";
import { renderToString } from "react-dom/server";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";

const DetectAsset = ({ asset, highlighted, onClick }) => {
  const { formState } = useContext(FormContext);
  const { clientSettings } = formState;
  const [showInfo, setShowInfo] = useState(false);
  const map = useMap();
  const colourBlindSettingEnabled = useSelector((state) => state.settings.isColorBlindEnabled);
  const colourBlindEnabled =
    clientSettings.Features.ColourBlindEnabled && colourBlindSettingEnabled;

  const cableZoomTrigger = parseInt(clientSettings.General.CableZoomTrigger);
  const cableZoomWeightMultiplier = parseInt(clientSettings.General.CableZoomWeightMultiplier);

  const toggleInfoPopup = () => {
    setShowInfo(!showInfo);
  };

  const assetInfoPopup = (marker) => {
    const icon = L.divIcon({
      className: "resultMarker",
      html: renderToString(
        <div style={{ marginTop: -40 }}>
          <div className="Tooltip-Wrapper">
            <div className="Tooltip-Tip left">
              <div>
                <div className="d-flex flex-row justify-content-between">
                  <div>
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        marginRight: 5,
                      }}
                    >
                      FAULT
                    </span>
                    impedance <b>{marker.impedance}</b> at ({" "}
                    <span className="text-light font-weight-bold">
                      {marker.geometry.lat.toFixed(6)}
                    </span>
                    ,{" "}
                    <span className="text-light font-weight-bold">
                      {marker.geometry.lng.toFixed(6)}
                    </span>
                    )
                  </div>
                  <div className="justify-content-center">
                    <Button
                      id="closeBtn"
                      size="sm"
                      color="danger"
                      style={{
                        height: 16,
                        width: 16,
                        marginTop: -3,
                        marginRight: -3,
                      }}
                      onClick={() => setShowInfo(!showInfo)}
                    >
                      <div
                        style={{ marginLeft: -7, marginTop: -4 }}
                        className="icon-cross-solid"
                      ></div>
                    </Button>
                  </div>
                </div>
                <br />
                <span className="text-light font-weight-bold">
                  {marker.distanceToTransformer.toFixed(2)} metres
                </span>{" "}
                from the substation
                <br />
                <br />
                <span className="text-light font-weight-bold pt-1">
                  {marker.distanceToClosestNode.toFixed(2)} metres
                </span>{" "}
                from the closest joint at (
                <span className="text-light font-weight-bold">
                  {marker.node.geometry.lat.toFixed(6)}
                </span>
                ,{" "}
                <span className="text-light font-weight-bold">
                  {marker.node.geometry.lng.toFixed(6)})
                </span>
              </div>
            </div>
          </div>
        </div>,
      ),
    });
    return icon;
  };

  const styleIcon = (asset) => {
    asset.styles.cssClass = asset.styles.class;
    return pinMarker(asset);
  };

  const getLineColor = () => {
    return highlighted
      ? color.BLUE_HIGHLIGHT
      : colourBlindEnabled
        ? color.COLOURBLIND_RED
        : color.RED;
  };

  const cableDash = (asset, defaultDashLength, defaultSpacingLength) => {
    let dash = "";

    if (!asset || !asset.cableGroup || !defaultDashLength || !defaultSpacingLength) return dash;

    let dashLength = defaultDashLength;
    let spacingLength = defaultSpacingLength;

    if (map.getZoom() > cableZoomTrigger) {
      dashLength = dashLength * cableZoomWeightMultiplier;
      spacingLength = spacingLength * cableZoomWeightMultiplier;
    }

    if (asset.cableGroup.endsWith("underground")) {
      return `${dashLength} ${spacingLength}`;
    }

    return dash;
  };

  const cableWeight = (asset) => {
    let weight = 2;

    if (!asset || !asset.cableGroup) return weight;

    if (asset.cableGroup === "mains-underground" || asset.cableGroup === "mains-overhead") {
      weight = 4;
    }

    if (map.getZoom() > cableZoomTrigger) {
      weight = weight * cableZoomWeightMultiplier;
    }

    return weight;
  };

  return (
    <>
      {asset.styles.tool === "Line" && (
        <Pane name={`detect-asset-cables${uuid()}`} style={{ zIndex: 594 }}>
          <Polyline
            key={asset.id}
            id={asset.id}
            positions={asset.geometry}
            pathOptions={{
              weight: cableWeight(asset),
              dashArray: cableDash(asset, 4, 6),
              color: getLineColor(asset),
            }}
            snapIgnore={true}
            eventHandlers={{
              click: () => {
                onClick(asset);
              },
            }}
          />
        </Pane>
      )}
      {asset.styles.tool === "MapMarker" && (
        <>
          <Pane name={`detect-asset-markers${uuid()}`} style={{ zIndex: 999 }}>
            <Marker
              id={asset.id}
              position={asset.geometry}
              icon={styleIcon({ ...asset })}
              eventHandlers={{
                click: () => {
                  toggleInfoPopup();
                },
              }}
            />
          </Pane>
          {showInfo && (
            <Pane name={`detect-asset-info${uuid()}`} style={{ zIndex: 2000 }}>
              <Marker
                key={asset.id}
                position={asset.geometry}
                icon={assetInfoPopup(asset)}
                eventHandlers={{
                  click: () => {
                    toggleInfoPopup();
                  },
                }}
              />
            </Pane>
          )}
        </>
      )}
    </>
  );
};

export default DetectAsset;
