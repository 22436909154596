const returnAsset = (asset, value, constraint) => {
  if (asset[value]) {
    asset[`${value}Constraint`] = value.includes("maxVoltDropPercent")
      ? !asset[`${value}PassResult`]
      : value === "flickerDeltaVoltage"
        ? !asset["flickerPassResult"]
        : value === "flickerStartingDeltaVoltage"
          ? !asset["flickerStartingPassResult"]
          : value === "impedanceLoopOperating"
            ? !asset["impedanceLoopOperatingPassResult"]
            : value === "impedanceSourceLoopOperating"
              ? !asset["impedanceSourceLoopOperatingPassResult"]
              : value === "flickerP28Primary"
                ? !asset["flickerP28PrimaryPassResult"]
                : value === "flickerP28Secondary"
                  ? !asset["flickerP28SecondaryPassResult"]
                  : asset[value] > constraint;
  }
  return asset;
};

export const allFaults = (reference) => {
  const constraints = [];
  reference.filterResults.forEach((category) => {
    category.info.forEach((res) => {
      if (res.season) {
        ["All", "summer", "winter"].forEach((season) => {
          if (res.options) {
            res.options.forEach((option) => {
              constraints.push({
                value: season + option.value,
                constraint: option.constraint,
                constraintCount: 0,
              });
            });
          } else {
            constraints.push({
              value: season + res.value,
              constraint: res.constraint,
              constraintCount: 0,
            });
          }
        });
      } else {
        if (res.options) {
          res.options.forEach((option) => {
            constraints.push({
              value: option.value,
              constraint: option.constraint,
              constraintCount: 0,
            });
          });
        } else {
          constraints.push({
            value: res.value,
            constraint: res.constraint,
            constraintCount: 0,
          });
        }
      }
    });
  });
  return constraints;
};

export const setConstraints = (reference, _formState, network) => {
  const constraints = allFaults(reference);
  Object.values(network).forEach((n) => {
    if (Array.isArray(n)) {
      n.forEach((asset) => {
        constraints.forEach((c) => {
          if (asset[c.value]) {
            returnAsset(asset, c.value, c.constraint);
            c.constraintCount += asset[`${c.value}Constraint`] ? 1 : 0;
          }
          switch (c.value) {
            case "feederFuseSize":
              c.constraintCount += asset["hasFuseWarning"]
                ? 1
                : asset["hasFuseMaxCurrentWarning"]
                  ? 1
                  : 0;
              break;
          }
        });
      });
    }
  });
  return { network: network, constraints: constraints };
};
