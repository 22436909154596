import L from "leaflet";
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Marker, Polyline, useMapEvents } from "react-leaflet";
import leafletPip from "@mapbox/leaflet-pip";
import detectIt from "detect-it";
import rfdc from "rfdc";
import * as tab from "../constants/tabs";
import WarningModal from "./WarningModal";
import { refineRingfencedAssets } from "../utils/ringfenceFunctions";

import { ToolContext } from "../context/ToolContext";
import { getAllGeometryAssets } from "../app/networkSlice";

const clone = rfdc();

export const isRingfenced = (geometry, boundary) => {
  let ringfenced = true;
  if (Array.isArray(geometry)) {
    geometry.forEach((g) => {
      if (!leafletPip.pointInLayer([g.lat, g.lng], boundary).length) {
        ringfenced = false;
      }
    });
  } else {
    if (!leafletPip.pointInLayer([geometry.lat, geometry.lng], boundary).length) {
      ringfenced = false;
    }
  }
  return ringfenced;
};

export const getRingfencedTypes = (ringfenced) => {
  const _ringfenced = clone(ringfenced);
  _ringfenced.forEach((asset) => {
    asset.styles.type === "groupedConnection"
      ? asset.groupedConnectionPoints.length < 1
        ? (asset.styles.name2 = "Node")
        : (asset.styles.name2 = "Grouped Connection")
      : (asset.styles.name2 = asset.styles.name);
  });

  const reduced = _ringfenced.reduce((previous, current) => {
    let typeName = current.styles.name;
    if (current.cableGroup) {
      const prefix = current.cableGroup.includes("mains") ? "Mains" : "Service";
      typeName = `${prefix} ${typeName}`;
    }

    return (previous[typeName] = (previous[typeName] || 0) + 1), previous;
  }, {});
  const types = Object.keys(reduced).map((key) => ({
    name: key,
    count: reduced[key],
  }));

  return types;
};

export const createGeoJson = (drawBoundary) => {
  const boundary = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [drawBoundary.geometry.map((coords) => [coords.lat, coords.lng])],
    },
  };
  return L.geoJson(boundary);
};

export const getRingfencedNetworkAssets = (geometryAssets, drawBoundary) => {
  const boundary = createGeoJson(drawBoundary);
  let ringfenced = [];

  geometryAssets
    .filter((p) => isRingfenced(p.geometry, boundary))
    .forEach((p) => {
      ringfenced.push(p.asset);
    });

  return ringfenced;
};

const RingfenceTool = () => {
  const { toolState, setToolState } = useContext(ToolContext);
  const [isLegacyNetwork, setIsLegacyNetwork] = useState(false);
  const { drawBoundary, disableBoundary } = toolState;
  const [clickedPosition, setClickedPosition] = useState({});
  const [mousePosition, setMousePosition] = useState({});

  const allGeometryAssets = useSelector((state) => getAllGeometryAssets(state));
  const nodes = useSelector((state) => state.network.present.nodes);
  const cables = useSelector((state) => state.network.present.cables);

  useEffect(() => {
    if (drawBoundary && nodes.length) {
      setIsLegacyNetwork(true);
    }
  }, [drawBoundary]);

  const resetBoundary = (value) => {
    if (value !== true) {
      return;
    }
    const _toolState = toolState;
    _toolState.drawBoundary = false;
    setToolState(_toolState);
    setIsLegacyNetwork(false);
  };

  const styleIcon = () => {
    return iconMarker();
  };

  const iconMarker = () => {
    const icon = L.divIcon({
      name: "handle",
      className: "handle iconMarker iconMarker-sz-2",
      html: "<i class=icon-node></i>",
      iconSize: [8, 8],
    });
    return icon;
  };

  const terminateBoundary = () => {
    const _toolState = toolState;
    let _drawBoundary = drawBoundary;
    _drawBoundary.geometry.push(drawBoundary.geometry[0]);
    _toolState.drawBoundary = _drawBoundary;
    setToolState(_toolState);
    updateRingfenced();
  };

  const updateRingfenced = () => {
    const selectedAssets = getRingfencedNetworkAssets(allGeometryAssets, drawBoundary);

    const ringfenced =
      toolState.mode === "select-group"
        ? selectedAssets
        : refineRingfencedAssets(cables, selectedAssets);

    const ringfencedTypes = getRingfencedTypes(ringfenced);

    const isServiceLeaf = ringfenced.filter(
      (asset) => asset.groupedConnectionPoints?.length < 1 && asset.isServiceLeaf,
    );

    if (isServiceLeaf.length > 0) {
      ringfencedTypes.push({
        name: "Service End Node",
        count: isServiceLeaf.length,
      });
    }

    const _toolState = toolState;
    _toolState.ringfenced = ringfenced;
    _toolState.ringfencedTypes = ringfencedTypes;
    _toolState.disableBoundary = true;
    _toolState.activeTab = tab.PROPERTIES;
    _toolState.ringfenceUpdated = false;
    setToolState(_toolState);
  };

  useMapEvents({
    mousemove(e) {
      setMousePosition(e.latlng);
    },
    click(e) {
      if (toolState.ringfenced.length) return;
      setClickedPosition(e.latlng);
      const _toolState = toolState;
      let _drawBoundary = drawBoundary || { geometry: [] };
      _drawBoundary.geometry.push(e.latlng);
      _toolState.drawBoundary = _drawBoundary;
      setToolState(_toolState);
    },
  });

  return (
    <>
      {isLegacyNetwork && (
        <WarningModal
          item={true}
          action={resetBoundary}
          msg={"This is a legacy network. Please run assessment to update"}
          dismissLabel="Cancel"
          yesLabel="OK"
        />
      )}
      {drawBoundary && (
        <>
          <Marker
            position={drawBoundary.geometry[0]}
            icon={styleIcon()}
            eventHandlers={{
              click: () => {
                terminateBoundary();
              },
            }}
          />
          {detectIt.hasMouse && !disableBoundary && (
            <Polyline
              positions={[mousePosition, clickedPosition]}
              pathOptions={{
                dashArray: "5 10",
              }}
            ></Polyline>
          )}
          <Polyline positions={[drawBoundary.geometry]} />
        </>
      )}
    </>
  );
};

export default RingfenceTool;
