import React, { useContext, useRef, useState, useEffect, useMemo } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Pane } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import { v4 as uuid } from "uuid";
import Proj from "proj4leaflet";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import { useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import ControlTabs from "./ControlTabs";
import MapControls from "./MapControls";
import Navbar from "./Navbar";
import AlertModal from "./AlertModal";
import { isEmpty } from "../utils/isEmpty";

import GeoJsonLoader from "./GeoJsonLoader";
import Spinner from "./Spinner";
import * as layout from "../constants/layout";
import { isKeyboardShortcut } from "../utils/isKeyboardShortcut";

const crs = new Proj.CRS(
  "EPSG:27700",
  "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs",
  {
    resolutions: [
      896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75, 0.875, 0.4375, 0.21875,
      0.109375, 0.0546875, 0.02734375,
    ],
    origin: [-238375.0, 1376256.0],
  },
);

const ConnectLV = () => {
  const [id, setId] = useState();
  const { formState } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { clientSettings, reference } = formState;
  const [navbarHeight, setNavbarHeight] = useState(layout.NAVBAR_HEIGHT);
  const [keyboardShortcut, setKeyboardShortcut] = useState(false);
  const showTiles = useSelector((state) => state.settings.showMapBackground);
  const geoJson = useSelector((state) => state.settings.geoJson);
  const hideMap = useSelector((state) => state.network.present.hideMap);

  const {
    DefaultMapCenterLat,
    DefaultMapCenterLng,
    DefaultZoomLevel,
    MinZoomLevel,
    MaxZoomLevel,
    MaxNativeZoomLevel,
    Projection,
  } = clientSettings.Map;

  const crsProp = {};

  if (Projection === "EPSG27700") {
    crsProp.crs = crs;
  }

  const { HistoryModeEnabled, MapTilesEnabled, SideBarEnabled } = clientSettings.Features;

  const [currentMap, setCurrentMap] = useState();

  useEffect(() => {
    if (currentMap) {
      setToolState({ leafletMap: currentMap });
    }
  }, [currentMap]);

  const _onMoveEnd = (e) => {
    setId(uuid());
  };

  const { isLoading } = toolState;

  const handleWindowSizeChange = () => {
    const navbar = document.getElementById("ea_navbar");
    setNavbarHeight(navbar.clientHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const onKeyDown = (e) => {
    if (isKeyboardShortcut(e, HistoryModeEnabled)) {
      setKeyboardShortcut(true);
    } else {
      return;
    }
    const { ctrlKey, shiftKey, key } = e;
    const _toolState = toolState;
    _toolState.keyDown = { ctrlKey, shiftKey, key };
    setToolState(_toolState);
  };

  const onKeyUp = () => {
    if (keyboardShortcut) {
      setKeyboardShortcut(false);
    } else {
      return;
    }
    const _toolState = toolState;
    _toolState.keyDown = {};
    setToolState(_toolState);
  };

  const canvasRenderer = useMemo(
    () =>
      L.canvas({
        tolerance: parseInt(clientSettings.General.EasyClickTolerance),
      }),
    [],
  );

  const svgRenderer = useMemo(() => L.svg(), []);

  return (
    <div
      id="wrapper"
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      tabIndex="-1"
      style={{ width: "100%", height: "100%" }}
    >
      <Navbar />
      {reference && (
        <>
          {SideBarEnabled && (
            <Sidebar position={navbarHeight}>
              <ControlTabs />
            </Sidebar>
          )}
        </>
      )}
      {clientSettings.Map && (
        <MapContainer
          id="map"
          center={[DefaultMapCenterLat, DefaultMapCenterLng]}
          zoom={DefaultZoomLevel}
          doubleClickZoom={false}
          zoomControl={false}
          attributionControl={false}
          minZoom={MinZoomLevel}
          maxZoom={MaxZoomLevel}
          onMoveEnd={_onMoveEnd}
          ref={(mapInstance) => setCurrentMap(mapInstance)}
          renderer={canvasRenderer}
          {...crsProp}
        >
          <Pane name="tile-layer" style={{ zIndex: "-1" }}>
            {MapTilesEnabled && showTiles && !hideMap && (
              <TileLayer
                url={clientSettings.Urls.EndPoints.MapTiles}
                maxNativeZoom={parseInt(MaxNativeZoomLevel)}
                maxZoom={24}
              />
            )}
            {[clientSettings.General.GeoServerLayers]
              .filter((f) => geoJson.includes(f.name))
              .map((layer) => (
                <GeoJsonLoader id={id} layer={layer} />
              ))}
          </Pane>
          <MapControls renderer={canvasRenderer} fallbackRenderer={svgRenderer} />
        </MapContainer>
      )}
      {!isEmpty(toolState.alert) && <AlertModal />}
      {isLoading && <Spinner>{isLoading}</Spinner>}
    </div>
  );
};

export default ConnectLV;
