import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  ListGroupItem,
  ButtonGroup,
  Row,
  Col,
} from "reactstrap";
import { toPng } from "html-to-image";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";

import { saveFeedback } from "../services/apiService";
import { buildNetwork } from "../app/networkSlice";

const FeedbackForm = () => {
  const { accounts } = useMsal();

  const [includeScreenshot, setIncludeScreenshot] = useState(true);
  const [includeNetwork, setIncludeNetwork] = useState(true);
  const [screenshot, setScreenshot] = useState(null);
  const [feedbackErrors, setFeedbackErrors] = useState(null);
  const { formState } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { network } = formState;
  const { feedbackForm } = toolState;

  const builtNetwork = useSelector((state) => buildNetwork(state));

  useEffect(() => {
    if (feedbackForm) {
      saveScreenshot();
    }
  }, [feedbackForm]);

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (jsonRequest) => {
    network.loaded = true;
    includeScreenshot && (jsonRequest.Screenshot = screenshot);
    includeNetwork && (jsonRequest.Network = JSON.stringify({ ...builtNetwork, ...network }));
    jsonRequest.UserAgent = navigator.userAgent;

    const account = accounts[0];
    return saveFeedback(jsonRequest, account).then((response) => {
      if (response.messages && response.messages.length > 0) {
        setFeedbackErrors(response.messages);
      } else {
        toggle();
      }
    });
  };

  const toggle = () => {
    const _toolState = toolState;
    _toolState.feedbackForm = !toolState.feedbackForm;
    setToolState(_toolState);
    setFeedbackErrors("");
  };

  const saveScreenshot = () => {
    const printArea = document.getElementById("root");

    toPng(printArea)
      .then((dataURL) => {
        setScreenshot(dataURL);
        document.getElementById("thumbnail").src = dataURL;
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
      });
  };

  return (
    <Modal
      isOpen={feedbackForm}
      toggle={toggle}
      className="modal-dialog-centered"
      contentClassName="feedbackForm text-light"
    >
      <ListGroupItem className="bg-dark" style={{ textTransform: "uppercase" }}>
        Report Feedback
      </ListGroupItem>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <input
              type="text"
              name="ContactName"
              id="ContactName"
              className="form-control form-control-sm"
              placeholder="Name"
              ref={register({
                required: "Required",
              })}
            />
            {errors.ContactName && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors.ContactName.message}
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <input
              type="email"
              name="EmailAddress"
              id="EmailAddress"
              className="form-control form-control-sm"
              placeholder="Email"
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address",
                },
              })}
            />
            {errors.EmailAddress && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors.EmailAddress.message}
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <textarea
              type="text"
              name="Feedback"
              id="Feedback"
              className="form-control form-control-sm"
              placeholder="Feedback"
              rows="3"
              ref={register({
                required: "Required",
              })}
            ></textarea>
            {errors.Feedback && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors.Feedback.message}
              </div>
            )}
          </FormGroup>
          <Row noGutters>
            <Col className="mr-1">
              <FormGroup>
                <Button
                  color="dark"
                  size="sm"
                  block
                  onClick={() => setIncludeScreenshot(!includeScreenshot)}
                  className={includeScreenshot ? "active" : ""}
                  disabled={!screenshot}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Include Screenshot</span>
                    <i className={`icon-${includeScreenshot ? "check" : "cross"}-solid`}></i>
                  </div>
                </Button>
              </FormGroup>
            </Col>
            <Col className="ml-1">
              <FormGroup>
                <Button
                  color="dark"
                  size="sm"
                  block
                  onClick={() => setIncludeNetwork(!includeNetwork)}
                  className={includeNetwork ? "active" : ""}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Include Network</span>
                    <i className={`icon-${includeNetwork ? "check" : "cross"}-solid`}></i>
                  </div>
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <div className="mb-3" style={{ display: includeScreenshot ? "block" : "none" }}>
            <img id="thumbnail" src="" width="100%" height="100%" />
          </div>
          {feedbackErrors && (
            <div className="bg-danger text-white p-2 mb-3">
              {feedbackErrors.length > 1 ? (
                <ul>
                  {feedbackErrors.map((message) => (
                    <li>{message.description}</li>
                  ))}
                </ul>
              ) : (
                feedbackErrors[0].description
              )}
            </div>
          )}
          <ButtonGroup className="float-right">
            <Button color="primary" size="sm" type="submit" className="mr-2">
              Submit
            </Button>
            <Button color="secondary" size="sm" onClick={toggle}>
              Cancel
            </Button>
          </ButtonGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default FeedbackForm;
