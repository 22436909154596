import { useMsal } from "@azure/msal-react";
import NewWindow from "react-new-window";
import { Button, Container, Nav, NavItem, NavLink, Table } from "reactstrap";
import { ConfigData } from "./config/ConfigData";
import { FormContext } from "../context/FormContext";
import { useContext, useEffect, useRef, useState } from "react";
import ConfigTab from "./ConfigTab";
import ConfigSearch from "./ConfigSearch";
import { getSpecificReference, viewConfig } from "../services/apiService";
import Spinner from "./Spinner";
import { checkReduxNetworkPopulated, clearReduxNetwork } from "../app/networkSlice";
import { useDispatch, useSelector } from "react-redux";
import WarningModal from "./WarningModal";
import { ToolContext } from "../context/ToolContext";
import { clearHistoryAction } from "../app/undoable";
import { store } from "../app/store";
import { resetNetworkToolState } from "../utils/networkFunctions";

const ConfigWindow = ({ onUnload, data, theme }) => {
  const { accounts } = useMsal();
  const { REACT_APP_THEME } = process.env;

  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const dispatchRedux = useDispatch();

  const { reference } = formState;

  const [configData, setConfigData] = useState();
  const [activePage, setActivePage] = useState(configData?.pages[0]);
  const [optionSearchValue, setOptionSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showResetNetworkWarning, setShowResetNetworkWarning] = useState();
  const [currentVersion, setCurrentVersion] = useState({});
  const containerRef = useRef(null);

  const isReduxNetworkPopulated = useSelector((state) => checkReduxNetworkPopulated(state));

  const list = data;
  if (
    !list.find(
      (p) =>
        p.clientName === reference.version.client && p.versionNumber === reference.version.number,
    )
  ) {
    list.push({
      clientName: reference.version.client,
      versionNumber: reference.version.number,
      description: reference.version.description,
      releaseDate: reference.version.releaseDate,
    });
  }

  useEffect(() => {
    if (
      currentVersion.client !== reference.version.client ||
      currentVersion.number !== reference.version.number
    ) {
      load({
        clientName: reference.version.client,
        versionNumber: reference.version.number,
      });
    }
  }, [reference.version.client, reference.version.number]);

  const toggle = (page) => {
    if (activePage !== page) {
      setActivePage(page);
    }
  };

  const apply = (row, confirmed) => {
    if (!row) {
      setShowResetNetworkWarning();
      return;
    }
    if (isReduxNetworkPopulated && !confirmed) {
      setShowResetNetworkWarning(row);
      return;
    }

    setIsLoading(true);
    setShowResetNetworkWarning();

    if (isReduxNetworkPopulated) {
      dispatchRedux(clearReduxNetwork());
    }

    store.dispatch(clearHistoryAction());

    const _toolState = { ...toolState };
    resetNetworkToolState(_toolState);
    setToolState(_toolState);

    getSpecificReference(accounts[0], row.clientName, row.versionNumber).then((p) => {
      dispatch({
        form: "reference",
        obj: p,
        type: "REPLACE_STATE",
      });

      setIsLoading(false);
    });
  };

  const load = (row) => {
    setIsLoading(true);
    viewConfig(accounts[0], row.clientName, row.versionNumber).then((p) => {
      const c = new ConfigData(p);
      setActivePage(c.pages[0]);
      setConfigData(c);
      setCurrentVersion({ client: row.clientName, number: row.versionNumber });
      setIsLoading(false);
    });
  };

  const isConfigLoaded = (row) =>
    reference.version.client === row.clientName && reference.version.number === row.versionNumber;

  const getButton = (row) => {
    if (!currentVersion.client && isConfigLoaded(row)) {
      return null;
    }
    if (currentVersion.client === row.clientName && currentVersion.number === row.versionNumber) {
      const isCurrent = isConfigLoaded(row);
      if (!isCurrent) {
        return (
          <Button size="sm" color="success" className="ml-2" onClick={() => apply(row)}>
            Apply
          </Button>
        );
      }
      return null;
    }
    return (
      <Button size="sm" color="info" className="ml-2" onClick={() => load(row)}>
        View
      </Button>
    );
  };

  return (
    <NewWindow features={{ width: 1200, height: 600 }} onUnload={() => onUnload(false)} id="popup">
      {showResetNetworkWarning && (
        <WarningModal
          ref={containerRef}
          item={showResetNetworkWarning}
          action={(p) => apply(p, true)}
          dismissAction={false}
          msg={`Configuration cannot be altered for an open design. Start a new design with these settings?`}
          yesLabel="Reset"
          dismissLabel="Cancel"
        />
      )}
      <div ref={containerRef}>
        <Container className="report">
          <h2 className="mb-4">View Configuration Properties</h2>
          <div className="row">
            <Table
              id="version-table"
              className={`table-sm table-custom table-custom-${REACT_APP_THEME} table-sortable`}
            >
              <thead>
                <tr className="mb-4">
                  <th>Version</th>
                  <th>Description</th>
                  <th>Release Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((row) => (
                  <tr
                    className={isConfigLoaded(row) ? "active" : ""}
                    key={`${row.clientName} ${row.versionNumber}`}
                  >
                    <td>
                      {row.clientName} v{row.versionNumber}
                    </td>
                    <td>
                      {row.description}
                      {isConfigLoaded(row) && <span className="badge badge-success">Current</span>}
                    </td>
                    <td>{row.releaseDate}</td>
                    <td>{getButton(row)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {isLoading ? (
            <Spinner>Loading configuration</Spinner>
          ) : (
            <>
              <h3 className="mb-3">
                {currentVersion.client} v{currentVersion.number}
              </h3>
              <div className="row">
                <div className="col-md-2" style={{ fontSize: "0.8rem" }}>
                  <ConfigSearch
                    optionSearchValue={optionSearchValue}
                    setOptionSearchValue={setOptionSearchValue}
                  />
                  <Nav className="clickable-nav-links">
                    {configData?.pages
                      .filter(
                        (p) =>
                          optionSearchValue.trim() === "" || p.matchesSearch(optionSearchValue),
                      )
                      .map((p) => (
                        <NavItem key={p?.title}>
                          <NavLink
                            className={activePage?.title === p?.title ? "active" : ""}
                            onClick={() => toggle(p)}
                          >
                            {p.title}
                          </NavLink>
                        </NavItem>
                      ))}
                  </Nav>
                </div>
                <div className="col-md-10">
                  {activePage && <ConfigTab page={activePage} theme={theme} />}
                </div>
              </div>
            </>
          )}
        </Container>
      </div>
    </NewWindow>
  );
};

export default ConfigWindow;
