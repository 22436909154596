import { useMsal } from "@azure/msal-react";
import { listConfigs } from "../services/apiService";
import SecondaryButton from "./SecondaryButton";
import * as buttonStatus from "../constants/buttonStatus";
import { FormContext } from "../context/FormContext";
import { useContext, useState } from "react";

const ConfigNavbar = ({ setShowConfigWindow }) => {
  const { accounts } = useMsal();

  const [exporting, setExporting] = useState(buttonStatus.READY);

  const { formState } = useContext(FormContext);
  const { reference } = formState;

  const loadData = () => {
    setExporting(buttonStatus.RUNNING);

    listConfigs(accounts[0]).then((p) => {
      if (!p) {
        setExporting(buttonStatus.FAILED);
        setTimeout(() => setExporting(buttonStatus.READY), 3000);
        return;
      }
      setShowConfigWindow(p);
      setExporting(buttonStatus.READY);
    });
  };

  const text =
    reference.version?.number > 0
      ? `${reference.version?.client} v${reference.version?.number}`
      : "Configuration";

  return (
    <SecondaryButton
      id="viewConfigButton"
      loadingText="Opening"
      failedText="Failed"
      disabled={false}
      status={exporting}
      onClick={loadData}
      text={text}
      title="View Config"
    />
  );
};

export default ConfigNavbar;
