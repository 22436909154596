import React, { useState, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  ListGroupItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useMsal } from "@azure/msal-react";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const Profile = () => {
  const { REACT_APP_B2C } = process.env;
  const b2c = REACT_APP_B2C === "true";

  const { accounts, instance } = useMsal();
  const [name, setName] = useState(null);

  useEffect(() => {
    if (accounts.length > 0) {
      setName(accounts[0].name.split(" ")[0]);
    }
  }, [accounts]);

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  if (name) {
    return (
      <div className="d-flex align-items-center">
        <UncontrolledDropdown>
          <NavbarText className="py-0">
            <DropdownToggle tag="span" className="nav-link py-0 navbar-icon" caret>
              {name} <FontAwesomeIcon icon={faUser} />
            </DropdownToggle>
          </NavbarText>
          <DropdownMenu
            className="mt-2"
            right
            style={{
              background: "black",
              padding: 5,
              border: "gray 1px solid",
              borderRadius: 10,
              fontSize: 12,
            }}
          >
            {b2c && (
              <>
                <ListGroupItem
                  tag="a"
                  href={`${process.env.REACT_APP_PORTAL_BASE_URL}/profile`}
                  action
                  className="d-flex justify-content-between align-items-center text-light pt-2"
                >
                  Manage Profile
                </ListGroupItem>
                <DropdownItem
                  divider
                  style={{
                    borderTop: "0.1rem solid gray",
                    marginTop: 1,
                    marginBottom: -1,
                  }}
                />
              </>
            )}
            <ListGroupItem
              tag="a"
              action
              className="d-flex justify-content-between align-items-center text-light pt-2"
              onClick={() => handleLogout()}
            >
              Logout
            </ListGroupItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  } else {
    return null;
  }
};
export default Profile;
