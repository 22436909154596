import React, { useState, useContext } from "react";
import { Navbar, NavbarBrand, Nav, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faFileExport,
  faInfoCircle,
  faPrint,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import { getBillOfMaterials, postAssessments } from "../services/apiService";
import { useMsal } from "@azure/msal-react";

import RunAssessment from "./RunAssessment";
import EALogo from "./EALogo";
import FeedbackForm from "./FeedbackForm";
import ResetNetwork from "./ResetNetwork";
import ResultReportWindow from "./ResultReportWindow";
import CrownNavbar from "./CrownNavbar";
import Profile from "./Profile";
import WarningModal from "./WarningModal";
import Settings from "./Settings";
import { formatGroupedConnectionsForBoM } from "../utils/networkFunctions";
import { cableHasDistributedCustomer } from "../utils/errorFunctions";
import { useSelector } from "react-redux";
import { checkReduxNetworkPopulated } from "../app/networkSlice";
import { createCimNetwork } from "../model/cim/cimFactory";
import SecondaryButton from "./SecondaryButton";
import * as buttonStatus from "../constants/buttonStatus";
import ConfigNavbar from "./ConfigNavbar";
import ConfigWindow from "./ConfigWindow";

const NavBar = () => {
  const { REACT_APP_THEME, REACT_APP_B2B, REACT_APP_B2C } = process.env;
  const b2x = REACT_APP_B2B === "true" || REACT_APP_B2C === "true";
  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const [report, setReport] = useState(false);
  const [exporting, setExporting] = useState(buttonStatus.READY);
  const { reference, network, clientSettings } = formState;
  const { showResults, costingFailed, assessmentFailed } = toolState;
  const [showWarningsModal, setShowWarningsModal] = useState(false);
  const [showNoBoMWarningModal, setShowNoBoMWarningModal] = useState(false);
  const [showConfigWindow, setShowConfigWindow] = useState();
  const [bom, setBoM] = useState();
  const { accounts } = useMsal();
  const isReduxNetworkPopulated = useSelector((state) => checkReduxNetworkPopulated(state));
  const transformers = useSelector((state) => state.network.present.transformers);
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);
  const connectionPoints = useSelector((state) => state.network.present.connectionPoints);
  const cables = useSelector((state) => state.network.present.cables);
  const welders = useSelector((state) => state.network.present.welders);
  const motors = useSelector((state) => state.network.present.motors);
  const pointOfConnections = useSelector((state) => state.network.present.pointOfConnections);
  const nodes = useSelector((state) => state.network.present.nodes);
  const ratingType = useSelector((state) => state.settings.ratingType);
  const networkTitle = useSelector((state) => state.network.present.title);

  const toggle = () => {
    const _toolState = toolState;
    _toolState.feedbackForm = !toolState.feedbackForm;
    setToolState(_toolState);
  };

  const canReport = () => {
    if (clientSettings.Features.CostingEnabled) {
      getBoM();
    } else {
      setReport(true);
    }
  };

  const exportAssessment = async () => {
    if (exporting !== buttonStatus.READY) {
      return;
    }
    setExporting(buttonStatus.RUNNING);
    const jsonRequest = JSON.stringify({
      requestParameters: {},
      network: convertToCim(ratingType),
      assessmentOptions: {
        applyAdmdAdjustment: !toolState.skipAdmd,
      },
      configuration: reference.version
        ? {
            client: reference.version.client,
            version: reference.version.number,
          }
        : null,
    });

    const account = accounts[0];
    try {
      const response = await postAssessments(jsonRequest, account, {
        assessmentExporting: true,
      });

      if (response.headers["content-type"] === "application/json; charset=utf-8") {
        const data = JSON.parse(await response.data.text());
        errorHandling(data);
        throw new Error("Errors returned from API");
      }

      const href = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = href;
      let filename = networkTitle;
      if (filename === null || filename === undefined || filename.length === 0) {
        filename = clientSettings.General.DisplayName;
      }
      link.setAttribute("download", `${filename}.xlsx`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setExporting(buttonStatus.COMPLETED);
    } catch (error) {
      setExporting(buttonStatus.FAILED);
      console.error(error);
    }
    setTimeout(() => setExporting(buttonStatus.READY), 3000);
  };

  const convertToCim = (ratingType) => {
    return createCimNetwork(
      reference,
      cables,
      [
        ...nodes,
        ...[...groupedConnections].filter(
          (gc) => !gc.groupedConnectionPoints || !gc.groupedConnectionPoints.length,
        ),
      ],
      connectionPoints,
      transformers,
      [...groupedConnections].filter(
        (gc) => gc.groupedConnectionPoints && gc.groupedConnectionPoints.length,
      ),
      motors,
      welders,
      pointOfConnections,
      ratingType,
    );
  };

  const setToolStateProp = (prop, value) => {
    const _toolState = toolState;
    _toolState[prop] = {
      type: "warning",
      className: "warning",
      messages: value,
    };
    setToolState(_toolState);
  };

  const getBoM = async () => {
    setBoMState(undefined);

    const bomData = {
      transformers: transformers,
      connectionPoints: connectionPoints,
      cables: cables,
      groupedConnections: formatGroupedConnectionsForBoM(groupedConnections),
      excludeCosts: true,
    };

    const account = accounts[0];
    const response = await getBillOfMaterials(bomData, account);

    if (!response || response?.errors) {
      if (response?.errors) {
        if (cableHasDistributedCustomer(response.errors)) {
          setBoM(response.billOfMaterials);
          setShowNoBoMWarningModal(true);
          return;
        }
      }
      setToolStateProp("alert", [
        {
          description: "Sorry, there was an error retrieving the Bill of Materials",
        },
      ]);
      return;
    }

    if (response?.messages?.length > 0) {
      const showWarnings = response.messages.every((msg) => msg.level === "Warning");
      errorHandling(response, showWarnings);
      if (showWarnings) {
        setBoM(response.billOfMaterials);
        setShowWarningsModal(true);
      }
      return;
    }

    setBoMState(response.billOfMaterials);
    setReport(true);
  };

  const showBoM = (canProcess) => {
    setShowWarningsModal(false);
    setShowNoBoMWarningModal(false);

    if (canProcess !== true) {
      return;
    }

    setBoMState(bom);
    setReport(true);
  };

  const setBoMState = (value) => {
    dispatch({
      form: "billOfMaterials",
      obj: value,
      type: "REPLACE_STATE",
    });
  };

  const errorHandling = (error, showWarning) => {
    const _toolState = toolState;
    const data = buildErrorData(
      error.messages || [{ description: "An unexpected error occurred" }],
      showWarning,
    );
    _toolState.alert = showWarning ? null : data;
    _toolState.errors = data;
    _toolState.costingFailed = !showWarning;
    setToolState(_toolState);
  };

  const buildErrorData = (data, showWarning) => {
    return {
      type: "error",
      className: showWarning ? "warning" : "danger",
      messages: data,
      isWarning: showWarning,
    };
  };

  return (
    <>
      {showNoBoMWarningModal && (
        <WarningModal
          item={true}
          action={showBoM}
          msg={"Sorry, there is no BoM being estimated with distributed customers in the network."}
          dismissLabel="OK"
          hideYesButton={true}
          dismissAction={true}
        />
      )}
      {showWarningsModal && (
        <WarningModal
          item={true}
          action={showBoM}
          msg={"There are Bill of Materials warnings. Do you wish to continue?"}
          messages={toolState.errors.messages}
          yesLabel="Yes"
          dismissLabel="No"
        />
      )}
      <Navbar
        color={REACT_APP_THEME}
        className="navbar-dark bd-navbar"
        expand="lg"
        fixed="top"
        id="ea_navbar"
      >
        <NavbarBrand tag="div" className="mr-auto d-flex justify-content-start">
          <EALogo
            theme={REACT_APP_THEME}
            displayName={clientSettings.General.DisplayName}
            color="#fff"
            logoColor="#fff"
            logoBgColor="#fab274"
          />
          <span style={{ height: 19, marginTop: 6 }} className="badge badge-danger text-uppercase">
            v{clientSettings.General.Version}
          </span>
          {clientSettings.Features.CROWNEnabled && <CrownNavbar />}
        </NavbarBrand>
        {clientSettings.Features.HeaderButtonsEnabled && (
          <Nav navbar tag="div">
            {clientSettings.Features.ExportDebutReportEnabled ? (
              <SecondaryButton
                id="runAssessmentExport"
                loadingText="Exporting"
                failedText="Failed"
                completedText="Exported"
                disabled={
                  !toolState.showResults ||
                  !isReduxNetworkPopulated ||
                  transformers.length > 1 ||
                  assessmentFailed
                }
                status={exporting}
                onClick={exportAssessment}
                icon={faFileExport}
                title="Export Network"
              />
            ) : null}
            {
              <Button
                color="info"
                size="sm"
                className="mr-2 d-flex align-items-center"
                onClick={() => canReport(true)}
                disabled={
                  !toolState.showResults ||
                  !isReduxNetworkPopulated ||
                  assessmentFailed ||
                  (clientSettings.Features.CostingEnabled && costingFailed)
                }
              >
                <FontAwesomeIcon icon={faPrint} />
              </Button>
            }
            <Button
              color="info"
              size="sm"
              className="mr-2 d-flex align-items-center"
              onClick={toggle}
            >
              <FontAwesomeIcon icon={faComment} />
            </Button>
            {clientSettings.Urls.HelpUrl !== "" ? (
              <a
                color="info"
                className="mr-2 btn btn-info btn-sm d-flex align-items-center"
                href={`${clientSettings.Urls.HelpUrl}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </a>
            ) : null}
            {clientSettings.Urls.ReleaseNoteUrl !== "" ? (
              <a
                color="info"
                className="mr-2 btn btn-info btn-sm d-flex align-items-center"
                href={`${clientSettings.Urls.ReleaseNoteUrl}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </a>
            ) : null}
            {report && showResults && (
              <ResultReportWindow network={network} onUnload={(e) => setReport(e)} />
            )}
            {showConfigWindow && (
              <ConfigWindow
                data={showConfigWindow}
                onUnload={() => setShowConfigWindow(false)}
                theme={REACT_APP_THEME}
              />
            )}
            {clientSettings.Features.UseDataReferenceEnabled &&
              (clientSettings.Features.SelectAnyClientConfigEnabled ||
                clientSettings.Features.SelectOnlyClientConfigEnabled) && (
                <ConfigNavbar setShowConfigWindow={setShowConfigWindow} />
              )}
            <ResetNetwork />
            <FeedbackForm />
            <RunAssessment />
            {b2x && <Profile />}
            <Settings />
          </Nav>
        )}
      </Navbar>
    </>
  );
};
export default NavBar;
