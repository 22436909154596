import { FormGroup, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./CounterGroup.css";

const CounterGroup = ({
  items,
  balancedLoad,
  count,
  changeValue,
  selectedValue,
  setValue,
  numberOfPhases,
  handleBalancedLoad,
}) => {
  const findValue = (id) => {
    return selectedValue.find((val) => val.name === id).value;
  };

  const reduceValue = (id) => {
    return selectedValue.filter((f) => f.name !== id).reduce((a, c) => a + c.value, 0);
  };

  return items[0].phases.includes("Three") || items[0].phases.includes("Two") ? (
    <FormGroup>
      <Button
        color="dark"
        block
        onClick={() => handleBalancedLoad()}
        className={balancedLoad ? "active" : ""}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span>Balanced</span>
          <FontAwesomeIcon icon={balancedLoad ? faCheck : faTimes} />
        </div>
      </Button>
    </FormGroup>
  ) : (
    <ul className="list-group list-group-horizontal w-100 counter-group">
      {items.map((item, i) => (
        <li key={item.value} className="list-group-item counter-group-item">
          {item.value === "Auto" ? (
            <Button color="dark" size="sm" block onClick={() => setValue()}>
              Auto
            </Button>
          ) : (
            <div className="d-flex">
              <Button
                color="dark"
                size="sm"
                className="counter-btns"
                disabled={findValue(item.id) < 1 || numberOfPhases === "Three"}
                onClick={() => changeValue(item, findValue(item.id), findValue("Auto"), -1)}
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
              <div className="p-1 flex-grow-1">{item.value}</div>
              <Button
                color="dark"
                size="sm"
                className="counter-btns"
                disabled={Number(count) === reduceValue("Auto") || numberOfPhases === "Three"}
                onClick={() => changeValue(item, findValue(item.id), findValue("Auto"), 1)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          )}
          <div
            style={{
              display: "block",
              fontSize: "1rem",
              padding: "0.375rem 0.75rem",
            }}
          >
            {findValue(item.id)}
          </div>
        </li>
      ))}
    </ul>
  );
};
export default CounterGroup;
