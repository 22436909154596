import { useState, useContext } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";

import { resetNetworkToolState, resetNetworkElements } from "../utils/networkFunctions";
import WarningModal from "./WarningModal";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import { isRagNetworkPopulated, isDetectNetworkPopulated } from "../utils/networkFunctions";
import { useSelector, useDispatch } from "react-redux";
import { checkReduxNetworkPopulated, clearReduxNetwork } from "../app/networkSlice";

const ResetNetwork = () => {
  const [clear, setClear] = useState(false);
  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { crown, clientSettings, ragNetworks, detectNetworks } = formState;
  const dispatchRedux = useDispatch();
  const isReduxNetworkPopulated = useSelector((state) => checkReduxNetworkPopulated(state));
  const networkChanged = useSelector((state) => state.network.present.networkChanged);

  const getMessage = () => {
    if (clientSettings.Features.CROWNEnabled && crown.enquiryId) {
      return "Clear network (this will clear any CROWN scheme details) - are you sure?";
    }
    return "Clear network - are you sure?";
  };

  const clearNetwork = (value) => {
    setClear(false);

    if (value !== true) {
      return;
    }

    const _toolState = toolState;

    dispatchRedux(clearReduxNetwork());
    resetNetworkToolState(_toolState);
    resetNetworkElements();

    dispatch({
      form: "crown",
      obj: {},
      type: "REPLACE_STATE",
    });

    dispatch({
      form: "ragNetworks",
      obj: [],
      type: "REPLACE_STATE4",
    });

    dispatch({
      form: "detectNetworks",
      obj: [],
      type: "REPLACE_STATE4",
    });

    setToolState(_toolState);
  };

  return (
    <>
      {clear && <WarningModal item={true} action={clearNetwork} msg={getMessage()} />}
      <Button
        color="secondary"
        size="sm"
        className="mr-2 text-light"
        onClick={() => setClear(!clear)}
        disabled={
          !networkChanged &&
          !isRagNetworkPopulated(ragNetworks) &&
          !isReduxNetworkPopulated &&
          !isDetectNetworkPopulated(detectNetworks)
        }
      >
        <FontAwesomeIcon icon={faUndo} /> Reset Network
      </Button>
    </>
  );
};

export default ResetNetwork;
