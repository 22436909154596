// @flow

import ConductingEquipment from "../cim/conductingEquipment";
import { GroupedConnection } from "../viewModel/groupedConnection";
import { ConnectionPoint } from "../viewModel/connectionPoint";
import { GroupedConnectionPoint } from "../viewModel/groupedConnectionPoint";
import { getLatLngFromWktPoint } from "./geometryWktConverter";
import { createConnectionPointFromConductingEquipment } from "./connectionPointFactory";
import Terminal from "../cim/terminal";
import * as statusConstants from "../../constants/status";

export function createGroupedConnectionsFromConductingEquipment(
  reference: any,
  groupedConnectionconductingEquipments: ConductingEquipment[],
  subGroupedConnectionConductingEquipments: ConductingEquipment[],
): GroupedConnection {
  const geometryString = groupedConnectionconductingEquipments[0].getPropertyValue("geometry");
  const geometry = getLatLngFromWktPoint(geometryString);
  const groupConnectionId = groupedConnectionconductingEquipments[0].getPropertyValue("parentId");

  let groupedConnection: GroupedConnection = new GroupedConnection(
    groupConnectionId,
    geometry,
    [],
    null,
    reference.groundTypeOverrideDefaults.groundTypeOverride,
  );
  let phasesGroupConnection = [];

  groupedConnectionconductingEquipments.forEach((ce) => {
    let phases = [];

    if (isGroupedConnection(ce) && !isSubGroupConnection(ce) && hasParentId(ce)) {
      getPhaseAllocation(ce, phases);
      getPhaseAllocation(ce, phasesGroupConnection);

      const groupedConnectionPoint = getGroupedConnectionPoint(ce);
      //get subGroupedConnectionPoint with parentId being the id of current groupedConnectionPoint
      const id = ce.getPropertyValue("id");

      groupedConnectionPoint.subGroupConnectionPoints = getSubGroupConnectionPoints(
        subGroupedConnectionConductingEquipments,
        id,
      );

      subGroupedConnectionConductingEquipments
        .filter((ce) => ce.getPropertyValue("parentId") === id)
        .forEach((ce) => {
          getPhaseAllocation(ce, phases);
          getPhaseAllocation(ce, phasesGroupConnection);
        });

      groupedConnectionPoint.phaseAllocation = phases.join(" + ");

      groupedConnection.groupedConnectionPoints.push(groupedConnectionPoint);
    }
  });

  groupedConnection.phaseAllocation = phasesGroupConnection.join(" + ");

  groupedConnection.styles = {
    name: "Grouped Connection",
    class: "connection",
    type: "groupedConnection",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
  };

  //groupedConnection is a virtual connection, so we need to copy the debut result
  //so it is shown on map
  groupedConnection.annotation = groupedConnection.groupedConnectionPoints[0].annotation;
  groupedConnection.nodeNumber = groupedConnection.groupedConnectionPoints[0].nodeNumber;

  groupedConnection.maxVoltDropPercent =
    groupedConnection.groupedConnectionPoints[0].maxVoltDropPercent;

  groupedConnection.maxVoltDropPercentPassResult =
    groupedConnection.groupedConnectionPoints[0].maxVoltDropPercentPassResult;

  groupedConnection.maxVoltRiseTotal =
    groupedConnection.groupedConnectionPoints[0].maxVoltRiseTotal;

  groupedConnection.maxVoltRiseGeneration =
    groupedConnection.groupedConnectionPoints[0].maxVoltRiseGeneration;

  groupedConnection.maxVoltRiseUnbalance =
    groupedConnection.groupedConnectionPoints[0].maxVoltRiseUnbalance;

  groupedConnection.earthLoopImpedance =
    groupedConnection.groupedConnectionPoints[0].earthLoopImpedance;

  groupedConnection.threePhaseFaultCurrent =
    groupedConnection.groupedConnectionPoints[0].threePhaseFaultCurrent;

  groupedConnection.phaseToPhaseFaultCurrent =
    groupedConnection.groupedConnectionPoints[0].phaseToPhaseFaultCurrent;

  groupedConnection.singlePhaseToEarthFaultCurrent =
    groupedConnection.groupedConnectionPoints[0].singlePhaseToEarthFaultCurrent;

  groupedConnection.hasFuseWarning = groupedConnection.groupedConnectionPoints[0].hasFuseWarning;

  groupedConnection.impedanceLoopOperating =
    groupedConnection.groupedConnectionPoints[0].impedanceLoopOperating;

  groupedConnection.impedanceLoopOperatingPassResult =
    groupedConnection.groupedConnectionPoints[0].impedanceLoopOperatingPassResult;

  groupedConnection.impedanceSourceLoopOperating =
    groupedConnection.groupedConnectionPoints[0].impedanceSourceLoopOperating;

  groupedConnection.impedanceSourceLoopOperatingPassResult =
    groupedConnection.groupedConnectionPoints[0].impedanceSourceLoopOperatingPassResult;

  groupedConnection.impedanceLoopFault =
    groupedConnection.groupedConnectionPoints[0].impedanceLoopFault;

  groupedConnection.impedancePhaseOperating =
    groupedConnection.groupedConnectionPoints[0].impedancePhaseOperating;

  groupedConnection.impedanceSourcePhaseOperating =
    groupedConnection.groupedConnectionPoints[0].impedanceSourcePhaseOperating;

  groupedConnection.impedanceSourcePhasePhaseOperating =
    groupedConnection.groupedConnectionPoints[0].impedanceSourcePhasePhaseOperating;

  groupedConnection.impedancePhaseFault =
    groupedConnection.groupedConnectionPoints[0].impedancePhaseFault;

  groupedConnection.resistanceLoopOperating =
    groupedConnection.groupedConnectionPoints[0].resistanceLoopOperating;

  groupedConnection.resistanceSourceLoopOperating =
    groupedConnection.groupedConnectionPoints[0].resistanceSourceLoopOperating;

  groupedConnection.resistanceLoopFault =
    groupedConnection.groupedConnectionPoints[0].resistanceLoopFault;

  groupedConnection.resistancePhaseOperating =
    groupedConnection.groupedConnectionPoints[0].resistancePhaseOperating;

  groupedConnection.resistanceSourcePhaseOperating =
    groupedConnection.groupedConnectionPoints[0].resistanceSourcePhaseOperating;

  groupedConnection.resistanceSourcePhasePhaseOperating =
    groupedConnection.groupedConnectionPoints[0].resistanceSourcePhasePhaseOperating;

  groupedConnection.resistancePhaseFault =
    groupedConnection.groupedConnectionPoints[0].resistancePhaseFault;

  groupedConnection.reactanceLoopOperating =
    groupedConnection.groupedConnectionPoints[0].reactanceLoopOperating;

  groupedConnection.reactanceSourceLoopOperating =
    groupedConnection.groupedConnectionPoints[0].reactanceSourceLoopOperating;

  groupedConnection.reactanceLoopFault =
    groupedConnection.groupedConnectionPoints[0].reactanceLoopFault;

  groupedConnection.reactancePhaseOperating =
    groupedConnection.groupedConnectionPoints[0].reactancePhaseOperating;

  groupedConnection.reactanceSourcePhaseOperating =
    groupedConnection.groupedConnectionPoints[0].reactanceSourcePhaseOperating;

  groupedConnection.reactanceSourcePhasePhaseOperating =
    groupedConnection.groupedConnectionPoints[0].reactanceSourcePhasePhaseOperating;

  groupedConnection.reactancePhaseFault =
    groupedConnection.groupedConnectionPoints[0].reactancePhaseFault;

  groupedConnection.AllmaxVoltDropPercent =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltDropPercent;
  groupedConnection.AllmaxVoltDropPercentPeriod =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltDropPercentPeriod;
  groupedConnection.AllmaxVoltDropPercentPassResult =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltDropPercentPassResult;
  groupedConnection.AllmaxVoltDropPhase =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltDropPhase;
  groupedConnection.AllmaxVoltRiseTotal =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltRiseTotal;
  groupedConnection.AllmaxVoltRiseGeneration =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltRiseGeneration;
  groupedConnection.AllmaxVoltRiseUnbalance =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltRiseUnbalance;
  groupedConnection.AllmaxVoltRisePercentPeriod =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltRisePercentPeriod;
  groupedConnection.AllmaxVoltRisePhase =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltRisePhase;
  groupedConnection.AllmaxVoltage = groupedConnection.groupedConnectionPoints[0].AllmaxVoltage;
  groupedConnection.AllmaxVoltagePhase1 =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltagePhase1;
  groupedConnection.AllmaxVoltagePhase2 =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltagePhase2;
  groupedConnection.AllmaxVoltagePhase3 =
    groupedConnection.groupedConnectionPoints[0].AllmaxVoltagePhase3;
  groupedConnection.AllminVoltage = groupedConnection.groupedConnectionPoints[0].AllminVoltage;
  groupedConnection.AllminVoltagePhase1 =
    groupedConnection.groupedConnectionPoints[0].AllminVoltagePhase1;
  groupedConnection.AllminVoltagePhase2 =
    groupedConnection.groupedConnectionPoints[0].AllminVoltagePhase2;
  groupedConnection.AllminVoltagePhase3 =
    groupedConnection.groupedConnectionPoints[0].AllminVoltagePhase3;
  groupedConnection.wintermaxVoltDropPercent =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltDropPercent;
  groupedConnection.wintermaxVoltDropPercentPeriod =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltDropPercentPeriod;
  groupedConnection.wintermaxVoltDropPercentPassResult =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltDropPercentPassResult;
  groupedConnection.wintermaxVoltDropPhase =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltDropPhase;
  groupedConnection.wintermaxVoltRisePercent =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltRisePercent;
  groupedConnection.wintermaxVoltRiseTotal =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltRiseTotal;
  groupedConnection.wintermaxVoltRiseGeneration =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltRiseGeneration;
  groupedConnection.wintermaxVoltRiseUnbalance =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltRiseUnbalance;
  groupedConnection.wintermaxVoltRisePercentPeriod =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltRisePercentPeriod;
  groupedConnection.wintermaxVoltRisePhase =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltRisePhase;
  groupedConnection.wintermaxVoltage =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltage;
  groupedConnection.wintermaxVoltagePhase1 =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltagePhase1;
  groupedConnection.wintermaxVoltagePhase2 =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltagePhase2;
  groupedConnection.wintermaxVoltagePhase3 =
    groupedConnection.groupedConnectionPoints[0].wintermaxVoltagePhase3;
  groupedConnection.winterminVoltage =
    groupedConnection.groupedConnectionPoints[0].winterminVoltage;
  groupedConnection.winterminVoltagePhase1 =
    groupedConnection.groupedConnectionPoints[0].winterminVoltagePhase1;
  groupedConnection.winterminVoltagePhase2 =
    groupedConnection.groupedConnectionPoints[0].winterminVoltagePhase2;
  groupedConnection.winterminVoltagePhase3 =
    groupedConnection.groupedConnectionPoints[0].winterminVoltagePhase3;
  groupedConnection.summermaxVoltDropPercent =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltDropPercent;
  groupedConnection.summermaxVoltDropPercentPeriod =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltDropPercentPeriod;
  groupedConnection.summermaxVoltDropPercentPassResult =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltDropPercentPassResult;
  groupedConnection.summermaxVoltDropPhase =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltDropPhase;
  groupedConnection.summermaxVoltRisePercent =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltRisePercent;
  groupedConnection.summermaxVoltRiseTotal =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltRiseTotal;
  groupedConnection.summermaxVoltRiseGeneration =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltRiseGeneration;
  groupedConnection.summermaxVoltRiseUnbalance =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltRiseUnbalance;
  groupedConnection.summermaxVoltRisePercentPeriod =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltRisePercentPeriod;
  groupedConnection.summermaxVoltRisePhase =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltRisePhase;
  groupedConnection.summermaxVoltage =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltage;
  groupedConnection.summermaxVoltagePhase1 =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltagePhase1;
  groupedConnection.summermaxVoltagePhase2 =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltagePhase2;
  groupedConnection.summermaxVoltagePhase3 =
    groupedConnection.groupedConnectionPoints[0].summermaxVoltagePhase3;
  groupedConnection.summerminVoltage =
    groupedConnection.groupedConnectionPoints[0].summerminVoltage;
  groupedConnection.summerminVoltagePhase1 =
    groupedConnection.groupedConnectionPoints[0].summerminVoltagePhase1;
  groupedConnection.summerminVoltagePhase2 =
    groupedConnection.groupedConnectionPoints[0].summerminVoltagePhase2;
  groupedConnection.summerminVoltagePhase3 =
    groupedConnection.groupedConnectionPoints[0].summerminVoltagePhase3;
  groupedConnection.flickerDeltaVoltage =
    groupedConnection.groupedConnectionPoints[0].flickerDeltaVoltage;
  groupedConnection.flickerP28Limit = groupedConnection.groupedConnectionPoints[0].flickerP28Limit;
  groupedConnection.flickerPassResult =
    groupedConnection.groupedConnectionPoints[0].flickerPassResult;
  groupedConnection.flickerStartingDeltaVoltage =
    groupedConnection.groupedConnectionPoints[0].flickerStartingDeltaVoltage;
  groupedConnection.flickerStartingPassResult =
    groupedConnection.groupedConnectionPoints[0].flickerStartingPassResult;
  groupedConnection.status =
    groupedConnection.groupedConnectionPoints[0].status ?? statusConstants.EXISTING;
  groupedConnection.cutOutSize = groupedConnection.groupedConnectionPoints[0].cutOutSize;
  groupedConnection.multiOccupancyContainer =
    groupedConnection.groupedConnectionPoints[0].multiOccupancyContainer;
  groupedConnection.multiOccupancy = groupedConnection.groupedConnectionPoints[0].multiOccupancy;
  groupedConnection.moSpareWays = groupedConnection.groupedConnectionPoints[0].moSpareWays;
  groupedConnection.linkBox = groupedConnection.groupedConnectionPoints[0].linkBox;
  groupedConnection.potEnds = groupedConnection.groupedConnectionPoints[0].potEnds;
  groupedConnection.overrideGroundType =
    groupedConnection.groupedConnectionPoints[0].overrideGroundType;
  groupedConnection.pole = groupedConnection.groupedConnectionPoints[0].pole;
  groupedConnection.phaseImpedance = groupedConnection.groupedConnectionPoints[0].phaseImpedance;
  groupedConnection.loopImpedance = groupedConnection.groupedConnectionPoints[0].loopImpedance;
  groupedConnection.rootTransformerId =
    groupedConnection.groupedConnectionPoints[0].rootTransformerId;

  return groupedConnection;
}

var getPhaseAllocation = function (ce, phases) {
  if (
    parseInt(ce.getPropertyValue("phase1AutoConsumers") ?? 0) > 0 ||
    parseInt(ce.getPropertyValue("phase1Consumers") ?? 0) > 0
  ) {
    if (phases.indexOf("L1") === -1) phases.push("L1");
  }

  if (
    parseInt(ce.getPropertyValue("phase2AutoConsumers") ?? 0) > 0 ||
    parseInt(ce.getPropertyValue("phase2Consumers") ?? 0) > 0
  ) {
    if (phases.indexOf("L2") === -1) phases.push("L2");
  }

  if (
    parseInt(ce.getPropertyValue("phase3AutoConsumers") ?? 0) > 0 ||
    parseInt(ce.getPropertyValue("phase3Consumers") ?? 0) > 0
  ) {
    if (phases.indexOf("L3") === -1) phases.push("L3");
  }
};

export function hasParentId(conductingEquipment: ConductingEquipment): boolean {
  const parentIdProperty = conductingEquipment.getPropertyByName("parentId");

  return parentIdProperty !== undefined && Boolean(parentIdProperty.value);
}

export function isGroupedConnection(conductingEquipment: ConductingEquipment): boolean {
  const subGroupConnectionProperty = conductingEquipment.getPropertyByName(
    "subGroupConnectionPoints",
  );

  return subGroupConnectionProperty !== undefined;
}

export function isExistingConsumer(conductingEquipment: ConductingEquipment): boolean {
  const existingConsumerProperty = conductingEquipment.getPropertyByName("existingConsumer");

  return existingConsumerProperty !== undefined;
}

export function isExistingConsumerTerminal(terminal: Terminal): boolean {
  return terminal.getPropertyValue("existingConsumer") === "true";
}

export function isSubGroupConnection(conductingEquipment: ConductingEquipment): boolean {
  return checkBooleanProperty(conductingEquipment, "isSubGroupConnection");
}

function checkBooleanProperty(conductingEquipment: ConductingEquipment, propertyName: string) {
  const property = conductingEquipment.getPropertyByName(propertyName);

  if (property === undefined || property.value === undefined || property.value === null)
    return false;

  return property.value.toLowerCase() === "true";
}

function getSubGroupConnectionPoints(
  conductingEquipments: ConductingEquipment[],
  parentId: String,
): ConnectionPoint[] {
  let subGroupConnectionPoints: ConnectionPoint[] = [];

  conductingEquipments
    .filter((ce) => ce.getPropertyValue("parentId") === parentId)
    .forEach((ce) =>
      subGroupConnectionPoints.push(createConnectionPointFromConductingEquipment(ce)),
    );

  return subGroupConnectionPoints;
}

function getGroupedConnectionPoint(
  conductingEquipment: ConductingEquipment,
): GroupedConnectionPoint {
  const connectionPoint = createConnectionPointFromConductingEquipment(conductingEquipment);

  const parentId = conductingEquipment.getPropertyValue("parentId");

  const groupedConnectionPoint = new GroupedConnectionPoint(
    connectionPoint.id,
    connectionPoint,
    [],
    conductingEquipment.getPropertyValue("count"),
    parentId,
  );

  return groupedConnectionPoint;
}
